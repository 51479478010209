import { BaseModel } from '~/models/baseModel';
import { EntityModel } from '~/models/entityModel';

export class EntityDirectorySearchResponseModel extends BaseModel {
    public entities:EntityModel[];
    public totalPages:number;
    public totalItems:number;

    constructor(values?:{}) {
        super(values);
    }
}