import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { SpecieAbstractService } from '~/services/api/web/specie/specieAbstractService';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { SpecieModel } from '~/models/specieModel';
import { ClipModel } from '~/models/clipModel';
import { Observable } from 'rxjs';
import { AssignSellerModel } from '~/models/assignSellerModel';
import { LineModel } from '~/models/lineModel';
import { ResponseModelCode } from '~/models/responseModel';
import { map as _map } from 'lodash';

@Injectable()
export class SpecieService extends BaseWebService implements SpecieAbstractService {

    public static readonly PATH:string = '/species';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getSpecies() : Observable<ResponseModel> {
        let url:string = SpecieService.URL + SpecieService.PATH;
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (specie) => new SpecieModel(specie));
        });
    }

    public getSpecieById(specieId:number) : Observable<ResponseModel>  {
        let url:string = SpecieService.URL + SpecieService.PATH + '/' + specieId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new SpecieModel(response['data']);
        });
    }

    public getSpeciesByClip(clip:ClipModel) : Observable<ResponseModel> {
        let url:string = SpecieService.URL + SpecieService.PATH;

        let params = new HttpParams();
        params = params.append('clipId', clip.clipId.toString());

        let options:{} = {
            params : params
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (specie) => new SpecieModel(specie));
        });
    }

    public postSpecie(specie : SpecieModel) : Observable<ResponseModel> {
        let url:string = SpecieService.URL + SpecieService.PATH;
        let body:any = specie;
        let options:{} = {};

        return this.http.post(url, body, options, (response:HttpResponse<any>) => {

            if(response['code'] === ResponseModelCode.Ok && response['data'].specie) {
                return new SpecieModel(response['data'].specie);
            }
            return null;
        });
    }

    public deleteSpecie(specie : SpecieModel) : Observable<ResponseModel> {

        let url:string = SpecieService.URL + SpecieService.PATH + '/' + specie.specieId.toString() + '/delete';
        let body:any = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public reverseSpeciStatus(specieId:number) : Observable<ResponseModel> {
        let url:string = SpecieService.URL + SpecieService.PATH + '/' + specieId.toString() + '/reverse';
        let body:any = null;
        let options:{} = {};
        return this.http.post(url,body,options);
    }

    public postLinesToSpecie(specie : SpecieModel, lineModels:LineModel[]) : Observable<ResponseModel> {
        let url:string = SpecieService.URL + SpecieService.PATH + '/' + specie.specieId + '/lineswithorder';
        let body:any = lineModels;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public submitSpecie(specie: SpecieModel) : Observable<ResponseModel> {
        let url:string = SpecieService.URL + SpecieService.PATH + '/' + specie.specieId.toString() + '/submit_specie';
        let body = null;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public assignToSeller(assignSellerModel : AssignSellerModel) : Observable<ResponseModel> {
        let url:string = SpecieService.URL + SpecieService.PATH + '/assign_to_seller';
        let body:any = assignSellerModel;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public saveWoolGrowerSignature(specie:SpecieModel, filePayload:FormData) : Observable<ResponseModel> {
        let headers = new HttpHeaders();
        headers = headers.delete('Content-Type');

        let url = SpecieService.URL + SpecieService.PATH + '/' + specie.specieId.toString() + '/save_woolgrower_signature';
        let body:any = filePayload;
        let options:{} = {
            headers: headers
        };

        return this.http.post(url, body, options);
    }

    public saveClasserSignature(specie:SpecieModel, filePayload:FormData) : Observable<ResponseModel> {
        let headers = new HttpHeaders();
        headers = headers.delete('Content-Type');

        let url:string = SpecieService.URL + SpecieService.PATH + '/' + specie.specieId.toString() + '/save_classer_signature';
        let body:any = filePayload;
        let options:{} = {
            headers: headers
        };

        return this.http.post(url, body, options);
    }
}
