import { Injectable } from '@angular/core';
import { HttpResponse, HttpParams } from '@angular/common/http';

// Services
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { OrderBookAbstractService } from '~/services/api/web/orderBook/orderBookAbstractService';

// Models
import { ResponseModel } from '~/models/responseModel';
import { DisputeModel } from '~/models/disputeModel';
import { EntityModel } from '~/models/entityModel';
import { OfferModel } from '~/models/offerModel';
import { OrderBookServerPaginationModel } from '~/models/serverPagination/orderBookServerPaginationModel';

// RxJS
import { Observable } from 'rxjs';

// Lodash
import { map as _map, isNil as _isNil } from 'lodash';

// Moment
import * as moment from 'moment';
import { DisputePostModel } from '../../../../models/disputePostModel';
import { UpdateOfferAccountInputModel } from '~/models/updateOfferAccountInputModel';

@Injectable()
export class OrderBookService extends BaseWebService implements OrderBookAbstractService {

    public static readonly PATH: string = '/orderbook';

    public getOrderBookBuying(savedSearchId:number = null, page:number = null, itemsPerPage:number = null, search:string = '', date:moment.Moment = null, status:string = '', accountName:string = '', catalogueId:number = null) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/buy';

        let options = {
            params : this.getBuyingSellingParam(savedSearchId,page,itemsPerPage,search,date,status,accountName, catalogueId)
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new OrderBookServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getBulletinboardBuying(savedSearchId:number = null, page:number = null, itemsPerPage:number = null, search:string = '', date:moment.Moment = null, status:string = '', accountName:string = '', catalogueId:number = null) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/bulletinboard/buyer';

        let options = {
            params : this.getBuyingSellingParam(savedSearchId,page,itemsPerPage,search,date,status,accountName, catalogueId)
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new OrderBookServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getOrderBookSelling(savedSearchId:number = null, page:number = null, itemsPerPage:number = null, search:string = '', date:moment.Moment = null, status:string = '', accountName:string = '', catalogueId:number = null) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/sell';

        let options = {
            params : this.getBuyingSellingParam(savedSearchId,page,itemsPerPage,search,date,status,accountName, catalogueId)
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new OrderBookServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getBulletinboardSelling(savedSearchId:number = null, page:number = null, itemsPerPage:number = null, search:string = '', date:moment.Moment = null, status:string = '', accountName:string = '', catalogueId:number = null) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/bulletinboard/seller';

        let options = {
            params : this.getBuyingSellingParam(savedSearchId,page,itemsPerPage,search,date,status,accountName, catalogueId)
        };

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new OrderBookServerPaginationModel<OfferModel>(OfferModel, response['data']);
        });
    }

    public getDisputes() : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/disputes';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (dispute) => new DisputeModel(dispute));
        });
    }

    public getDisputeById(disputeId:number) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/dispute/' + disputeId.toString();
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new DisputeModel(response['data']);
        });
    }

    public getDisputesByEntity(entity:EntityModel) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/entity/' + entity.entityId.toString() + '/disputes';
        let options:{} = {};

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return _map(response['data'], (dispute) => new DisputeModel(dispute));
        });
    }

    public postDispute(dispitePost:DisputePostModel) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/dispute';
        let body:any = dispitePost;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postDisputeApprove(dispute:DisputeModel) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/dispute/' + dispute.id.toString() + '/approved';
        let body:any = dispute;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postDisputeCancel(dispute:DisputeModel) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/dispute/' + dispute.id.toString() + '/cancelled';
        let body:any = dispute;
        let options:{} = {};

        return this.http.post(url, body, options);
    }

    public postAccountDetails(offerAccounts:UpdateOfferAccountInputModel[]) : Observable<ResponseModel> {
        let url:string = OrderBookService.URL + OrderBookService.PATH + '/updateaccount';
        let body = offerAccounts;
        let options = {};

        return this.http.post(url, body, options);
    }

    private getBuyingSellingParam(savedSearchId:number = null, page:number = null, itemsPerPage:number = null, search:string = '', date:moment.Moment = null, status:string = '', accountName:string = '', catalogueId:number = null) : HttpParams {
        let params = new HttpParams();

                if(!_isNil(savedSearchId)) {
                    params = params.append('savedSearchId', savedSearchId.toString());
                }
                if(!_isNil(page)) {
                    params = params.append('page', page.toString());
                }
                if(!_isNil(itemsPerPage)) {
                    params = params.append('pageSize', itemsPerPage.toString());
                }
                if(!_isNil(search)) {
                    params = params.append('search', search.toString());
                }
                if(!_isNil(date)) {
                    params = params.append('date', date.format('YYYY-MM-DD'));
                }
                if(!_isNil(status)) {
                    params = params.append('status', status);
                }
                if(!_isNil(accountName)) {
                    params = params.append('accountName', accountName);
                }
                if(!_isNil(catalogueId)) {
                    params = params.append('catalogueId', catalogueId.toString());
                }
        return params;
    }
}
