import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '~/services/api/web/account/accountService';

@Injectable()
export class AdminGuard implements CanActivate {

    private accountService: AccountService;
    private router: Router;

    constructor(accountService: AccountService, router: Router) {
        this.accountService = accountService;
        this.router = router;
    }

    public canActivate() : Observable<boolean> {
        return this.accountService.isUserAdmin().pipe(
            map((response) => {
                if (response?.data === true){
                    return true;
                }
                this.router.navigate(['/dashboard']);
            })
        );
    }
}
