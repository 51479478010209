import { BaseModel } from '~/models/baseModel';

export class DataflowSettingsModel extends BaseModel {
    public lpbIngestion: boolean;
    public additiveDO: boolean;
    public type: string;

    public hasValues() : boolean {
        let hasValues = (typeof this.lpbIngestion !== 'undefined') || 
        (typeof this.additiveDO !== 'undefined') || 
        (typeof this.type !== 'undefined');

        return hasValues;
    }
}
