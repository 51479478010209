import { BaseModel } from '~/models/baseModel';
import { WoolStoreLocationModel } from '~/models/woolStoreLocationModel';
import { ClasserModel } from '~/models/classerModel';
import { QualitySchemeModel } from '~/models/qualitySchemeModel';
import { UserModel } from '~/models/userModel';
import { SellerServiceModel } from '~/models/sellerServiceModel';
import { BrandModel } from '~/models/brandModel';
import { FtpConnectionModel } from '~/models/ftpConnectionModel';
import { BankModel } from '~/models/bankModel';
import { WoolGrowerExtensionModel } from '~/models/woolGrowerExtensionModel';
import { PartnerModel } from '~/models/partnerModel';
import { CertificateModel } from '~/models/certificateModel';
import { GalleryImageModel } from '~/models/galleryImageModel';
import { DefaultSpeciSettingModel } from '~/models/defaultSpeciSettingModel';
import { SellerFeesModel } from '~/models/sellerFeesModel';
import { SellerModel } from '~/models/sellerModel';
import { BuyerModel } from '~/models/buyerModel';
import { forEach as _forEach } from 'lodash';
import { GroupSubscriberModel } from './groupSubscriberModel';
import { ProcessorModel } from './overseasProcessorModel';

export class EpiModel extends BaseModel {
    public entityId:number;
    public entityName:string;
    public businessDescription:string;
    public abn:string;
    public acn:string;

    public streetAddress:string;
    public streetAddress2:string;

    public city:string;
    public state:string;
    public country:string;
    public suburb:string;
    public postcode:string;
    public wsa:string;
    public telephone:string;
    public mobile:string;
    public email:string;
    public website:string;

    // bank details
    public bsb:string;
    public bankName:string;
    public accountNo:string;
    public swiftCode:string;
    public iban:string;

    public isActive:boolean;
    public isWoolGrower:boolean;
    public isClasser:boolean;
    public isSeller:boolean;
    public isBuyer:boolean;
    public isContractor:boolean;
    public isPresser:boolean;
    public isProcessor:boolean;
    public isBrand:boolean;
    public isGroup:boolean;

    public brandPubliclyVisible:boolean;

    public woolgrowerExtension:WoolGrowerExtensionModel;
    public processorExtension:ProcessorModel;
    public classerExtension:ClasserModel;
    public buyerExtension:BuyerModel;
    public sellerExtension:SellerModel;
    public defaultSpeciSetting:DefaultSpeciSettingModel;
    public sellerFee:SellerFeesModel;
    public ftpConnection:FtpConnectionModel;

    public storeLocations:WoolStoreLocationModel[] = [];
    public entitySellerServices:SellerServiceModel[] = [];
    public partners:PartnerModel[] = [];
    public brands:BrandModel[] = [];
    public certificates:CertificateModel[] = [];
    public users:UserModel[] = [];
    public primaryContacts:UserModel[] = [];
    public galleryImages:GalleryImageModel[] = [];
    public groups: GroupSubscriberModel[] = [];
    public group: GroupSubscriberModel[];

    public businessLogoStoredFileName:string;
    public businessLogoUploadedName:string;
    public businessPhotoStoredFileName:string;
    public businessPhotoUploadedName:string;
    public businessVideoUploadedName:string;

    public canAddPartner:boolean;
    public publicVisible:boolean;

    public woolProduced:string[];

    constructor(values: Object = {}) {
        super(values);

        let init : {}[] = [
            {'prop' : 'classerExtension', 'prot': ClasserModel.prototype},
            {'prop' : 'processorExtension', 'prot': ProcessorModel.prototype},
            {'prop' : 'sellerFee', 'prot': SellerFeesModel.prototype},
            {'prop' : 'woolgrowerExtension', 'prot': WoolGrowerExtensionModel.prototype},
            {'prop' : 'processorExtension', 'prot': ProcessorModel.prototype},
            {'prop' : 'defaultSpeciSetting', 'prot': DefaultSpeciSettingModel.prototype},
            {'prop' : 'sellerExtension', 'prot': SellerModel.prototype},
            {'prop' : 'buyerExtension', 'prot': BuyerModel.prototype},
            {'prop' : 'ftpConnection', 'prot': FtpConnectionModel.prototype},
        ];

        _forEach(init, (item:any) => {
            let prop = item.prop;
            let prot = item.prot;

            values[prop] = (typeof values[prop] !== 'undefined') ? values[prop] : {};
            this[prop] =  this.instantiate(prot, values[prop]);
        });

        let initDeep : {}[] = [
            {'prop' : 'storeLocations', 'prot': WoolStoreLocationModel.prototype},
            {'prop' : 'entitySellerServices', 'prot': SellerServiceModel.prototype},
            {'prop' : 'partners', 'prot': PartnerModel.prototype},
            {'prop' : 'brands', 'prot': BrandModel.prototype},
            {'prop' : 'certificates', 'prot': CertificateModel.prototype},
            {'prop' : 'users', 'prot': UserModel.prototype},
            {'prop' : 'galleryImages', 'prot': GalleryImageModel.prototype},
            {'prop' : 'groups', 'prot': GroupSubscriberModel.prototype }
        ];

         _forEach(initDeep, (item:any) => {
            let prop = item.prop;
            let prot = item.prot;
            if (typeof values[prop] === 'undefined') {
                return;
            }

            _forEach(values[prop], (data:{}) => {
                let item = this.instantiate(prot, data);
                this[prop].push(item);
            });
        });

        if (typeof values['primaryContacts'] !== 'undefined') {
            _forEach(values['primaryContacts'], (service:any) => {
                let _service = new UserModel(service);
                this.primaryContacts.push(_service);
            });
        }
    }

    get accountName() {
        return this.bankName;
    }

    set accountName(value) {
        this.bankName = value;
    }

    get type() : string {
        let type = '';

        type = (this.isWoolGrower) ? 'Woolgrower' : type;
        type = (this.isSeller) ? 'Seller' : type;
        type = (this.isClasser) ? 'Classer' : type;
        type = (this.isBuyer) ? 'Buyer' : type;
        type = (this.isContractor) ? 'Contractor / Other' : type;
        type = (this.isGroup) ? 'Group' : type;
        type = (this.isProcessor) ? 'Processor' : type;
        type = (this.isBrand) ? 'Brand' : type;
        return type;
    }

    set type(type:string) {
        this.isWoolGrower = (type === 'Woolgrower');
        this.isSeller = (type === 'Seller');
        this.isClasser = (type === 'Classer');
        this.isBuyer = (type === 'Buyer');
        this.isContractor = (type === 'Contractor / Other');
        this.isGroup = (type === 'Group');
        this.isProcessor = (type === 'Processor');
        this.isBrand = (type === 'Brand');
    }

    public userIs(userType:string) : boolean {
        return (this.type === userType);
    }

    /**
     * Create sub models when import on construct
     */
    private instantiate(prot:Function, data:any) : any {
        let obj = Object.create(prot, {});
        obj = Object.assign(obj, data);

        return obj;
    }

    set bank(bank:BankModel){
        this.bankName = bank.bankName;
        this.bsb = bank.bsb;
        this.accountNo = bank.accountNo;
        this.iban = bank.iban;
        this.swiftCode = bank.swiftCode;
    }

    get bank() : BankModel {
        return new BankModel({
            'bsb': this.bsb,
            'accountNo': this.accountNo,
            'accountName': this.accountName,
            'iban': this.iban,
            'swiftCode': this.swiftCode,
        });
    }

    set stores(stores:WoolStoreLocationModel[]) {
        this.storeLocations = stores;
    }

    set buyer(buyer:BuyerModel) {
        this.buyerExtension = buyer;
    }

    set woolGrowerExtension(woolGrowerExtension:WoolGrowerExtensionModel) {
        this.woolgrowerExtension = woolGrowerExtension;
    }

    set qualityScheme(qualityScheme:QualitySchemeModel) {
        this.woolgrowerExtension.qualitySchemas = qualityScheme.qualitySchemas;
    }
}
