 <ng-container *ngFor="let i of images">
    <div *ngIf="!i.src" style="width:30px;height:30px"
        class="wep-ag-grid-cell-renderer-styled-image__image"
        [css]="'mh30 mw30 ' + (i.css ?? '')">
    </div>
     <wep-image *ngIf="i.src" class="wep-ag-grid-cell-renderer-styled-image__image"
     [css]="'mh30 mw30 ' + (i.css ?? '')"
     [title]="i.title ?? ''" 
     (click)="onClick()"
     [src]="i.src">
    </wep-image>
</ng-container>
