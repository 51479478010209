<div id="woolclips">
<div class="heading">
    <h4 class="heading__title">
        How to link your AWTH and WoolClip accounts:
    </h4>
</div>
<p>
    WoolClip<sup>TM</sup> is an online internet and mobile app that
    allows the user to create wool Specifications, Consignments and
    National Wool Declarations (NWDs). It uses a simple, intuitive
    workflow designed to reduce workload and errors.
</p>
<p>
    To link your account to WoolClip, you must have a farm brand. You
    must also be registered on WoolClip.
</p>
<ol *ngIf="generic === false">
    <li>Press 'Get Code' to the right of the screen</li>
    <li>
        Copy the 6-digit code that is generated. Code will be live for 2
        hours.
    </li>
    <li>Go to WoolClip and enter your code into the platform.</li>
    <li>Click Link Accounts</li>
    <li>Done</li>
</ol>
<ol *ngIf="generic === true">
    <li>Go to your business profile</li>
    <li>Select the brand/users tab</li>
    <li>Select edit on the brand you wish to link to WoolClip</li>
    <li>Follow on page instructions.</li>
</ol>
</div>