import { BaseModel } from '~/models/baseModel';
import { NumberFilterModel } from '~/models/numberFilterModel';
import { StartsWithFilterModel } from '~/models/startsWithFilterModel';
import { EndsWithFilterModel } from '~/models/endsWithFilterModel';
import { SelectFilterModel } from '~/models/selectFilterModel';
import { StringFilterModel } from './stringFilterModel';

export class SearchModel extends BaseModel {
    public micron:NumberFilterModel[];
    public vm:NumberFilterModel[];
    public str:NumberFilterModel[];
    public yieldtype:SelectFilterModel[];
    public ct_slen:NumberFilterModel[];
    public ct_pobm:NumberFilterModel[];
    public ct_haut:NumberFilterModel[];
    public typestr:StartsWithFilterModel[];
    public stcenter:StringFilterModel[];
    public seller:StringFilterModel[];
    public excl:EndsWithFilterModel[];
}
