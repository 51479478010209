import { LotModel } from '~/models/lotModel';

export class InventoryLotModel extends LotModel {

    public folio: number;
    public seller: string;
    public buyer: string;
    public wsa: string;
    public awexID: string;
    public classer: string;
    public classedBy: string;
    public classerID: number;
    public classerComments: string;
    public specie: string;
    public balesCount: number;
    public description: string;
    public finalisedDate: string;
    public mobsCount: number;
    public weight: string;
    public brand: string;
    public shareTestDataWithGrower:boolean;
    public shareBrandDataWithBuyer:boolean;
    public shareOrderDataWithGrower:boolean;

    public client: string;
    public coreCertId: string;
    public lengthStrengthCertId: string;
    public clientCrossRef: string;
    public lotIdentifier: string;
    public noBales: number;
    public baleDescription: string;
    public mulesingStatus: string;
    public micron: string;
    public avgBaleWeight: string;
    public woolBase: string;
    public vmb: string;
    public vM1: string;
    public vM2: string;
    public vM3: string;
    public yield: string;
    public stapleLength: string;
    public stapleLengthCV: string;
    public strength: number;
    public tmb: string;
    public pointOfBreakTip: number;
    public pointOfBreakMid: number;
    public pointOfBreakBaseh: number;
    public hauteur: number;
    public coefficientOfVariation: string;
    public standardDeviation: string;
    public comfortFactor: string;
    public curvatureMean: string;
    public orderSummary: string;
    public buyerName: string;
    public deliveryDate: string;
    public dumpLocationy: string;
    public finalDestination: string;
    public pickUpDate: string;
    public portOfDischarge: string;
    public purchaseDate: string;
    public storeLocation: string;
    public orderDetail: string;
    public orderNumber: string;
    public combinationCertificateID: string;
    public combinationCertificateDate: string;

    constructor(values: Object = {}) {
        super(values);
    }
}
