import { LineModel } from '~/models/lineModel';
import { MobModel } from '~/models/mobModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export enum InventoryLineModelType {
    Line = 'Line',
    Lot = 'Lot'
};

export class InventoryLineModel extends LineModel {
    public id:number;

    public comment:string;
    public classerName:string;
    public seller:string;

    @Moment()
    public startDate:moment.Moment;
    public mobs:MobModel[];

    public type:InventoryLineModelType;
}
