<div class="tpl--admin__header">
    <wep-tpl-admin-heading>
    </wep-tpl-admin-heading>
</div>
<div class="tpl--admin__container flex-grow-max">
    <!---<wep-tpl-admin-sidebar>
    </wep-tpl-admin-sidebar>-->
    <div id="tpl--admin__main" class="tpl--admin__main">
        <div class="tpl--admin__content">
            <router-outlet></router-outlet>
        </div>
        <!--<div class="tpl--admin__overlay"
             [@overlay]="offCanvasAnimationState"
             *ngIf="isCanvasStateTablet()"
             [ngClass]="{'tpl--admin__overlay--active' : isOverlayActive() }"
             (click)="closeCanvas()">
        </div>-->
        <wep-tpl-admin-conversations class="tpl--admin__conversations">
        </wep-tpl-admin-conversations>
    </div>
</div>
