import { BaseModel } from '~/models/baseModel';

export class LotTestResultModel extends BaseModel {
    public lotIdentifier:string;
    public client:string;
    public awexId:string;
    public coreCertId:string;
    public lengthStrengthCertId:string;
    public clientCrossRef:string;
    public noBales:number;
    public centre:number;
    public baleDescription:string;
    public mulesingStatus:string;
    public finalSaleValue:number;
    public micron:number;
    public weight:number;
    public avgBaleWeight:number;
    public woolBase:number;
    public vmb:string;
    public vm1:number;
    public vm2:number;
    public vm3:number;
    public yield:number;
    public yield1:number;
    public yield2:number;
    public yield3:number;
    public yield4:number;
    public yield5:number;
    public yieldType1:string;
    public yieldType2:string;
    public cleanWeight1:number;
    public cleanWeight2:number;
    public cleanWeight3:number;
    public cleanWeight4:number;
    public cleanWeight5:number;
    public stapleLength:number;
    public stapleLengthCV:number;
    public strength:number;
    public tmb:string;
    public pointOfBreakTip:number;
    public pointOfBreakMid:number;
    public pointOfBreakBase:number;
    public hauteur:number;
    public hauteurCV:number;
    public coefficientOfVariation:number;
    public standardDeviation:number;
    public comfortFactor:number;
    public curvatureMean:number;
    public canEditTestData:boolean;

}
