import { BaseModel } from '~/models/baseModel';

export class AuctionCatalogEditModel extends BaseModel {
    public id:number;
    public name:string;

    public date:string;

    public open:string;
    public close:string;

    public notified:number;

    public season:string;
    public saleNo:string;
    public sellerEntityId:number;
    public sellerEntity:string;
}