import { PermissionModel } from '~/models/permissionModel';
import { LineModel } from '~/models/lineModel';
import { MobModel } from '~/models/mobModel';
import { BaleModel } from '~/models/baleModel';
import { QualitySchemeModel } from '~/models/qualitySchemeModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';
import { sumBy as _sumBy, forEach as _forEach, uniqBy as _uniqBy, map as _map } from 'lodash';

export class SpecieModel extends PermissionModel {
    public specieId:number;
    public entityID:number;
    public specieNo:number;
    public brandID:number;
    public clipReference:string;
    public clipID:number;
    public classer_EntityID:number;
    public classer_ContactID:number;
    public sellerID:number;
    public classerName:string;
    public classerPhone:string;
    public classerEmail:string;
    public classerID:number;
    public comments:string;
    public qualitySchemas:QualitySchemeModel[];
    public weight:number;
    public submittedContactID:number;

    public shearingComplete:boolean;
    public mulesingCeased:boolean;
    public mulesedPurchased:boolean;
    public hasLambMulesed:boolean;

    //Signature URLS
    public woolgrowerSignatureStoredFileName:string;
    public classerSignatureStoredFileName:string;

    //Might have to move these values to a different object someday.
    //But the relationship between seller and specie is 1 to 1
    public sellerEmail:string;
    public sellerSpecialInstructions:string;
    public sellerSaleInstructions:string[] = []; // An array value of sales instructions

    @Moment()
    public createdDate:moment.Moment;

    @Moment()
    public specieDate:moment.Moment;
    public specieReference:string;

    public saleNumber:string;

    public secretKey:string;

    public isSubmitted:boolean;

    @Moment()
    public submittedDate:moment.Moment;

    //Assign to Seller
    public canAssign:boolean;

    //Statuses
    get status () : string {
      return  this.getStatusDisplay();
    }
    public testStatus:string;

    //Associated Lines
    public lines:LineModel[] = [];

    constructor(values?:{}) {
        super(values);

        if(this.qualitySchemas) {
            let qualitySchemas:QualitySchemeModel[] = [];

            _forEach(this.qualitySchemas, (qualitySchema) => {
                qualitySchemas.push(new QualitySchemeModel(qualitySchema));
            });
            this.qualitySchemas = qualitySchemas;
        }
        else {
            this.qualitySchemas = [];
        }
    }

    get lineIds(): number[] {
        return _map(this.lines, (line:LineModel) => line.lineId);
    }

    /**
     * Retrieves the count of the lines
     * @returns number
     */
    public getLinesCount() : number {
        return this.lines.length;
    }

    /**
     * Retrieves the count of the bales that are in the lines
     * @returns number
     */
    public getBalesCount() : number {
        return _sumBy(this.lines, (line:LineModel) => {
            return line.getBalesCount();
        });
    }

    /**
     * Retrieves the count of all the mobs in the specie.
     */
    public getMobsCount() : number {
        //Return the length of the mobs
        return this.getUniqueMobs().length;
    }

    /**
     * Retrieves all the mobs in the specie.
     * This does not count duplicate mobs. As a mob can be put in multiple bales
     * @returns {any}
     */
    public getUniqueMobs() : MobModel[] {
        let bales:BaleModel[] = [];
        let mobs:MobModel[] = [];

        //Get all the bales in all the lines
        _forEach(this.lines, (line:LineModel) => {
            // _uniqBy();
            bales.push.apply(bales, line.bales);
        });

        //Get all the mobs in all the bales
        _forEach(bales, (bale:BaleModel) => {
            mobs.push.apply(mobs, bale.mobs);
        });

        //Get all the uniqe mobs
        return _uniqBy(mobs, (mob:MobModel) => {
            return mob.mobId;
        });
    }

    /**
     * Retrieves the display status
     * @returns {any}
     */
    public getStatusDisplay() : string {
        if(this.sellerEmail && this.isSubmitted) {
            return 'Assigned To Seller';
        }
        else if(this.isSubmitted) {
            return 'Finalised';
        }
        return 'In Progress';
    }

    /**
     * Retrieves the status priority based on number
     * Used for sorting
     * @returns {number}
     */
    public getStatusPriority() : number {
        if(this.sellerEmail && this.isSubmitted) {
            return 3;
        }
        else if(this.isSubmitted) {
            return 2;
        }
        return 1;
    }

    /**
     * Is the specie assigned to a seller
     * @returns boolean
     */
    public isAssignedToSeller() : boolean {
        return this.sellerEmail && this.isSubmitted;
    }

    public isAssignedToSellerString() : string {
        return (this.sellerEmail && this.isSubmitted) ? 'Yes' : 'No';
    }

    /**
     * Returns true (to display an alert icon) if if certain fields are not set
     * @returns {boolean}
     */
    public shouldDisplayAlert() : boolean {
        return !(this.lines.length > 0);
    }
}
