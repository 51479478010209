import { PermissionModel } from '~/models/permissionModel';
import { MobModel } from '~/models/mobModel';
import { SpecieModel } from '~/models/specieModel';
import { ClipModel } from '~/models/clipModel';
import { LineModel } from '~/models/lineModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';
import { sumBy as _sumBy, sortBy as _sortBy } from 'lodash';

export class BaleModel extends PermissionModel {
    public baleId:number;
    public clipID:number;
    public clipReference:string;
    public brandID:number;
    public specieID:number;
    public lineID:number;
    public baleNo:number;
    public eBaleId:string;
    public binCode:string;
    public woolDescription:string;
    public weight:number; //Default Bale Weight
    public comments:string;
    public onTruck:boolean;
    public mobRange:string;

    @Moment()
    public onTruckDate:moment.Moment;
    public onTruckBy:string;

    public mobIDs:number[];
    public mobNos:number[];
    public classerName:string;
    public images:string[];
    public hide:boolean;

    @Moment()
    public createdDate:moment.Moment;

    public isSubmitted:boolean;

    //Local ID’s
    public localSpecieID:string;
    public localBrandID:string;
    public localLineID:string;
    public localLotID:string;

    //Statuses
    public status:string;
    public testStatus:string;

    //Associated Mobs
    public mobs:MobModel[];
    public species:SpecieModel[];

    //Associated Line
    public line:LineModel;
    public clip:ClipModel;

    public tareKg:number;
    public sellerRef:string;

    public disableWeightDialog?:boolean;

    constructor(values?:{}) {
        super(values);

        if(!this.mobs) {
            this.mobs = [];
        }

        if(!this.species) {
            this.species = [];
        }

        if(!this.weight) {
            this.weight = null;
        }
    }

    /**
     * Returns the average woolLength of mobs in this bale
     * @returns {number}
     */
    public getAverageWoolLength() : number {
        let woolLength = 0;

        woolLength = _sumBy(this.mobs, (mob:MobModel) => {
            return +mob.averageLength;
        });

        return woolLength / this.mobs.length;
    }

    /**
     * Returns the mobs in the bale by the mob number
     * @returns {MobModel[]}
     */
    public getSortedMobs() : MobModel[] {
        return _sortBy(this.mobs, (mob:MobModel) => {
            return mob.mobNo;
        });
    }

    /**
     * Prints the bale details
     * @returns {string}
     */
    public printDetails() : string {
        let details:string[] = [];

        if(this.woolDescription) {
            details.push(this.woolDescription);
        }

        if(this.binCode) {
            details.push(this.binCode);
        }

        return details.join(' - ');
    }

    /**
     * Returns true (to display an alert icon) if if certain fields are not set
     * @returns {boolean}
     */
    public shouldDisplayAlert() : boolean {
        return !this.weight ||
                !this.woolDescription ||
                this.weight > 240 ||
                this.weight < 90 ||
                !(this.mobs.length > 0);
    }
}
