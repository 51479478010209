import { Injectable } from '@angular/core';
import { isNil as _isNil } from 'lodash';
import * as moment from 'moment';

// Model
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { UserPreferenceModel } from '~/models/userPreferenceModel';

// Rxjs
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// Services
import { UserPreferenceService } from '~/services/api/web/userPreference/userPreferenceService';
import { LocalService } from '~/services/storage/local/localService';
import { SnackBarService } from '~/components/snackBar/services/snackBarService';

export let UserPreferenceKeyNames:any = {
    App: {
        Version : {
           Old: 'app.version.old',
           Current: 'app.version.current'
        },
        Templates: {
            Admin: {
                SelectedEntity: 'app.templates.admin.selectedEntity',
                SelectedBrand: 'app.templates.admin.selectedBrand',
                Conversations: {
                    SelectedConversationUser: 'app.templates.admin.conversations.selectedConversationUser'
                }
            }
        },
        Pages: {
            Admin: {
                Dashboard: {
                    Micron: 'app.pages.admin.dashboard.micron'
                },
                ESpeci: {
                    MobSortBy: 'app.pages.admin.especi.mobSortBy',
                    WoolBookSortBy: 'app.pages.admin.especi.woolBookSortBy',
                    LineSortBy: 'app.pages.admin.especi.lineSortBy',
                    ESpeciSortBy: 'app.pages.admin.especi.especiSortBy',
                },
                Shared: {
                    ReadyReckoner: {
                        SellingCostsNotification: 'app.pages.admin.shared.readyReckoner.selltingCostsNotification',
                        EstimatedMarketValue: 'app.pages.admin.shared.readyReckoner.estimatedMarketValue',
                        EditableTable: 'app.pages.admin.shared.readyReckoner.editableTable'
                    }
                },
                Inventory: {
                    Clip: {
                        Overview: {
                            MobBreakdown: 'app.pages.admin.inventory.clip.overview.mobBreakdown',
                            BaleProduction: 'app.pages.admin.inventory.clip.overview.baleProduction',
                            MicronPerformance: 'app.pages.admin.inventory.clip.overview.micronPerformance',
                            StapleStrength: 'app.pages.admin.inventory.clip.overview.stapleStrength',
                            VolumeSkirtRatio: 'app.pages.admin.inventory.clip.overview.volumeSkirtRatio',
                            YieldPerformanceRatio: 'app.pages.admin.inventory.clip.overview.vieldPerformanceRatio',
                        },
                    },
                    Speci: {
                        Analysis: 'app.pages.admin.inventory.speci.analysis'
                    },
                    Line: {
                        Analysis: 'app.pages.admin.inventory.line.analysis'
                    },
                    Lot: {
                        SavedFilter: 'app.pages.admin.inventory.lot.savedFilter',
                        Analysis: 'app.pages.admin.inventory.lot.analysis'
                    },
                    Order:{
                        SavedFilter: 'app.pages.admin.inventory.order.filter'
                    },
                    Export:{
                        SelectedFilter: 'app.pages.admin.inventory.export.filter'
                    }
                },
                Market: {
                    Shared: {
                        TileCarousel: {
                            SearchStates: 'app.pages.admin.market.shared.tileCarousel.searchStates',
                            SelectedSavedSearchId: 'app.pages.admin.market.shared.tileCarousel.selectedSavedSearchId'
                        },
                        OfferMenuItems: {
                            SelectedFilterYield: 'app.pages.admin.market.shared.offerMenuItems.selectedFilterYield',
                            SelectedFilterPrice: 'app.pages.admin.market.shared.offerMenuItems.selectedFilterPrice',
                        },
                        OrderBookMenuItems: {
                            SelectedFilterDate: 'app.pages.admin.market.shared.orderBookMenuItems.selectedFilterDate',
                            SelectedFilterType: 'app.pages.admin.market.shared.orderBookMenuItems.selectedFilterType',
                        },
                        AuctionCatalogDropdown: {
                            SelectedAuctionCatalog: 'app.pages.admin.market.shared.auctionCatalogDropdown.selectedAuctionCatalog'
                        }
                    }
                }
            }
        },
        PageTemplate: {
            Admin: {
                HeroCarousel: (path:string) => {
                    return 'app.pageTemplates.admin.heroCarousel.hide.' + path;
                }
            }
        }
    }
};

@Injectable()
export class UserPreferenceLocalService {

    public static readonly PATH:string = '/userpreference';

    private userPreferenceService:UserPreferenceService;
    private localStorage:LocalService;
    private snackBarService:SnackBarService;

    constructor(userPreferenceService:UserPreferenceService,
                localStorage:LocalService,
                snackBarService:SnackBarService) {
        this.userPreferenceService = userPreferenceService;
        this.localStorage = localStorage;
        this.snackBarService = snackBarService;

        //Retrieve the version set in localStorage
        let version = this.localStorage.get(UserPreferenceKeyNames.App.Version.Current);

        if(_isNil(version)) {
            //Set the current webpage version
            this.localStorage.set(UserPreferenceKeyNames.App.Version.Current, process.env['WEBPAGE_VERSION']);
        }
        else {
            //Set the oldVersion and the currentVersion
            this.localStorage.set(UserPreferenceKeyNames.App.Version.Old, version);
            this.localStorage.set(UserPreferenceKeyNames.App.Version.Current, process.env['WEBPAGE_VERSION']);
        }
        this.attemptMigrate();
    }

    public attemptMigrate() : void {
        let oldVersion = this.localStorage.get(UserPreferenceKeyNames.App.Version.Old);

        if(_isNil(oldVersion)) {
            return;
        }

        //Initial versioning of userPreference
        if(oldVersion === '1.3.5') {
        }

        this.localStorage.set(UserPreferenceKeyNames.App.Version.Old, null);
    }

    private getValue<T>(key:string,
                        getUserPreference:Observable<ResponseModel>,
                        localStorageSet:(key:string, value:T) => void,
                        localStorageGet:(key:string) => T) : Observable<UserPreferenceModel<T>> {
        if(this.inLocalStorage(key) && this.isFreshContent(key)) {
            return of(new UserPreferenceModel<T>({
                key: key,
                value: localStorageGet(key)
            }));
        }
        return getUserPreference.pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    localStorageSet(key, response.data.value);
                    return new UserPreferenceModel<T>({
                        key: key,
                        value: localStorageGet(key)
                    });
                }
                else {
                    this.snackBarService.openAsError(response.errorMessage);
                    return null;
                }
            })
        );
    }

    public getStringValue(key:string) : Observable<UserPreferenceModel<string>> {
        return this.getValue<string>(
            key,
            this.userPreferenceService.getUserPreferenceStringValue(key),
            (key:string, value:string) => {
                this.setStringToLocalStorage(key, value);
            },
            (key:string) => {
                return this.getStringFromLocalStorage(key);
            }
        );
    }

    public getBooleanValue(key:string) : Observable<UserPreferenceModel<boolean>> {
        return this.getValue<boolean>(
            key,
            this.userPreferenceService.getUserPreferenceBooleanValue(key),
            (key:string, value:boolean) => {
                this.setBooleanToLocalStorage(key, value);
            },
            (key:string) => {
                return this.getBooleanFromLocalStorage(key);
            }
        );
    }

    public getNumberValue(key:string) : Observable<UserPreferenceModel<number>> {
        return this.getValue<number>(
            key,
            this.userPreferenceService.getUserPreferenceNumberValue(key),
            (key:string, value:number) => {
                this.setNumberToLocalStorage(key, value);
            },
            (key:string) => {
                return this.getNumberFromLocalStorage(key);
            }
        );
    }

    public getObjectValue(key:string) : Observable<UserPreferenceModel<Object>> {
        return this.getValue<Object>(
            key,
            this.userPreferenceService.getUserPreferenceObjectValue(key),
            (key:string, value:Object) => {
                this.setObjectToLocalStorage(key, value);
            },
            (key:string) => {
                return this.getObjectFromLocalStorage(key);
            }
        );
    }

    private postValue<T>(key:string,
                         value:T,
                         postUserPreference:Observable<ResponseModel>,
                         localStorageSet:(key:string, value:T) => void,
                         localStorageGet:(key:string) => T) : Observable<UserPreferenceModel<T>> {
        let valueFromLocal:T = localStorageGet(key);
        if (this.inLocalStorage(key) &&
            this.isFreshContent(key) &&
            this.isSameValue<T>(valueFromLocal, value)) {
            return of(new UserPreferenceModel<T>({
                key: key,
                value: valueFromLocal
            }));
        }
        return postUserPreference.pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    localStorageSet(key, value);
                    return new UserPreferenceModel<T>({
                        key: key,
                        value: localStorageGet(key)
                    });
                }
                else if(response.code === ResponseModelCode.Error) {
                    this.snackBarService.openAsError(response.errorMessage);
                }
                return null;
            })
        );
    }

    public postStringValue(key:string, value:string) : Observable<UserPreferenceModel<string>>  {
        return this.postValue<string>(
            key,
            value,
            this.userPreferenceService.postUserPreferenceStringValue(key, value),
            (key:string, value:string) => {
                this.setStringToLocalStorage(key, value);
            },
            (key:string) => {
                return this.getStringFromLocalStorage(key);
            }
        );
    }

    public postNumberValue(key:string, value:number) : Observable<UserPreferenceModel<number>> {
        return this.postValue<number>(
            key,
            value,
            this.userPreferenceService.postUserPreferenceNumberValue(key, value),
            (key:string, value:number) => {
                this.setNumberToLocalStorage(key, value);
            },
            (key:string) => {
                return this.getNumberFromLocalStorage(key);
            }
        );
    }

    public postBooleanValue(key:string, value:boolean) : Observable<UserPreferenceModel<boolean>> {
        return this.postValue<boolean>(
            key,
            value,
            this.userPreferenceService.postUserPreferenceBooleanValue(key, value),
            (key:string, value:boolean) => {
                this.setBooleanToLocalStorage(key, value);
            },
            (key:string) => {
                return this.getBooleanFromLocalStorage(key);
            }
        );
    }

    public postObjectValue(key:string, value:Object) : Observable<UserPreferenceModel<Object>> {
        return this.postValue<Object>(
            key,
            value,
            this.userPreferenceService.postUserPreferenceObjectValue(key, value),
            (key:string, value:Object) => {
                this.setObjectToLocalStorage(key, value);
            },
            (key:string) => {
                return this.getObjectFromLocalStorage(key);
            }
        );
    }

    public deleteValue(key:string) : Observable<boolean> {
        return this.userPreferenceService.deleteUserPreference(key).pipe(
            map((response:ResponseModel) => {
                if(response.code === ResponseModelCode.Ok) {
                    this.localStorage.remove(key);
                    return true;
                }
                else if(response.code === ResponseModelCode.Error) {
                    this.snackBarService.openAsError(response.errorMessage);
                }
                return false;
            })
        );
    }

    private inLocalStorage(key:string) : boolean {
        return !_isNil(this.localStorage.get(key));
    }

    private isFreshContent(key:string) : boolean {
        let date = this.localStorage.get(key + '.update');

        if (_isNil(date)) {
            return false;
        }

        return this.isDateInValidRange(date, 24);
    }

    private isDateInValidRange(date:string, range:number) : boolean {
        let update = moment(new Date(date));
        let now = moment();
        let diff = this.timeDiff(now, update);

        return (diff < range);
    }

    private isSameValue<T>(oldValue:T, newValue:T) : boolean {
        return oldValue === newValue;
    }

    private timeDiff(start, end) : number {
        return start.diff(end, 'hours');
    }

    private setStringToLocalStorage(key:string, value:string) : void {
        this.localStorage.set(key, value);
        this.localStorage.set(key  + '.update', moment());
    }

    private setBooleanToLocalStorage(key:string, value:boolean) : void {
        this.localStorage.setBoolean(key, value);
        this.localStorage.set(key  + '.update', moment());
    }

    private setNumberToLocalStorage(key:string, value:number) : void {
        this.localStorage.setNumber(key, value);
        this.localStorage.set(key  + '.update', moment());
    }

    private setObjectToLocalStorage(key:string, value:Object) : void {
        this.localStorage.setObject(key, value);
        this.localStorage.set(key  + '.update', moment());
    }

    private getStringFromLocalStorage(key:string) : string {
        return this.localStorage.get(key);
    }

    private getBooleanFromLocalStorage(key:string) : boolean {
        return this.localStorage.getBoolean(key);
    }

    private getNumberFromLocalStorage(key:string) : number {
        return this.localStorage.getNumber(key);
    }

    private getObjectFromLocalStorage(key:string) : Object {
        return this.localStorage.getObject(key);
    }
}
