<div class="wep-page-tpl-footer__container">
    <wep-button class="wep-help-and-support__button" [style2]="true" [routerLink]="['/contact-us']">
        Contact Us
    </wep-button>
        <div *ngIf="socialLinks.length > 0">
            <div class="wep-page-tpl-footer__social clearfix">
                <a class="wep-page-tpl-footer__social__item" [href]="socialLink.link" *ngFor="let socialLink of socialLinks; trackBy: trackBySocialLink">
                    <i class="wep-page-tpl-footer__social__icon" [ngClass]="socialLink.iconClass"></i>
                </a>
            </div>
        </div>
        <div>
            <a class="wep-page-tpl-footer__link" href="/faqs/" target="_blank">FAQ</a>
            <a class="wep-page-tpl-footer__link" href="/privacy" target="_blank">Privacy</a>
            <a class="wep-page-tpl-footer__link" href="/terms" target="_blank">Terms and Conditions</a>
            <span class="wep-page-tpl-footer__copyright">© 2024 AWTH</span>
        </div>
</div>