import { BaseModel } from '~/models/baseModel';
import { QualitySchemasModel } from '~/models/constants/qualitySchemas';
import { MulesingModel } from '~/models/constants/mulesingModel';
import { CountryModel } from '~/models/constants/countryModel';
import { StateModel } from '~/models/constants/stateModel';
import { DisputeReasonModel } from '~/models/constants/disputeReasonModel';
import { ProcessorTypeModel } from './processorTypeModel';
import { forEach as _forEach } from 'lodash';

/**
 * Contains a list of constants from the API.
 * If it is just called by object instance without a service it only uses the predefined values set inside the constructor.
 */
export class ConstantsModel extends BaseModel {
    public binCodes:string[];
    public breedCodes:string[];
    public sellerIntegrationStatus:string[];
    public sellerSalesInstruction:string[];
    public buyerServices:string[];
    public contactTitles:string[];
    public contactTypes:string[];
    public countries:CountryModel[] = [];
    public genderCodes:any[];
    public mulesings:MulesingModel[];
    public nlisIdentificationTags:any[];
    public qualitySchemas:QualitySchemasModel[];
    public salesTerms:string;
    public vmCodes:any[];
    public vmQuantities:string[];
    public woolMajorTypes:string[];
    public woolQualities:string[];
    public woolSubTypes:string[];
    public disputeReasons:DisputeReasonModel[];
    public processorTypes:any[];
    public woolTypes:string[];
    public processorWoolTypes:string[];
    public auctionCatalogCloseFreeze:number;
    public auctionDurationInSecs:number;
    public auctionIntervalInSecs:number;

    constructor(values?:{}) {
        super(values);

        this.contactTitles = [
            'Mr',
            'Mrs',
            'Miss',
            'Ms',
            'Dr',
            'Prof',
            'Rev'
        ];


        this.countries.push(new CountryModel({
            name: 'AU',
            longName: 'Australia',
            states: [
                new StateModel({
                    name: 'NSW',
                    longName: 'New South Wales'
                }),
                new StateModel({
                    name: 'VIC',
                    longName: 'Victoria'
                }),
                new StateModel({
                    name: 'QLD',
                    longName: 'Queensland'
                }),
                new StateModel({
                    name: 'SA',
                    longName: 'South Australia'
                }),
                new StateModel({
                    name: 'NT',
                    longName: 'Northern Territory'
                }),
                new StateModel({
                    name: 'WA',
                    longName: 'Western Australia'
                }),
                new StateModel({
                    name: 'ACT',
                    longName: 'Australian Capital Territory'
                }),
                new StateModel({
                    name: 'TAS',
                    longName: 'Tasmania'
                })
            ]
        }));
        if(values && values['countries']) {
            _forEach(values['countries'], (cnt:string) => {
                if(cnt !== 'Australia') {
                    this.countries.push(new CountryModel({
                        longName: cnt
                    }));
                }
            });
        }
    }
}
