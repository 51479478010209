import { ErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from './monitoringService';

@Injectable()
export class CustomErrorHandler extends ErrorHandler {
    private monitoringService: MonitoringService;

    constructor(monitoringService: MonitoringService) {        
        super();

        this.monitoringService = monitoringService;
    }

    handleError(error: Error) {    
        console.error("ERROR", error);
        this.monitoringService.logException(error); // Manually log exception
    }
}