import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';

export interface CanDeactivateComponent {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {

  public canDeactivate(component: CanDeactivateComponent): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate();
  }
}