import { BaseModel } from '~/models/baseModel';

export class RegisteredUsersModel extends BaseModel {
   public accessLevel:string;
   public brandId: number
   public contactId: number;
   public dateCreated: string;
   public email: string;
   public entityName:string;
   public fullName: string;
   public firstName: string;
   public lastName: string;
   public type:string;
}