import { BrandPartnerRequestSource } from '~/constants/enums/brandPartnerRequestSource';
import { BaseModel } from '~/models/baseModel';

export class ReviewRequestModel extends BaseModel {
    public brandPartnerId:number;
    public isAccepted: number;
    public requestorEmail:string;
    public pics:string;
    public source:BrandPartnerRequestSource;
    public brandName:string;
    public email:string
}
