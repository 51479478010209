import { BaseModel } from '~/models/baseModel';

export class TenderCatalogEditModel extends BaseModel {
    public id:number;
    public name:string;

    public startDate: string;
    public endDate:string;
    public startTime:string;
    public endTime:string;
    public start:string;
    public end:string;

    public sellerEntityId:number;
    public ownerEntityId:number;
}
