import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ResponseModel, ResponseModelCode, ResponseModelErrorMessages } from '~/models/responseModel';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SnackBarService } from '~/components/snackBar/services/snackBarService';

@Injectable()
export class HttpClientWrapper {
    private http:HttpClient;
    private snackBarService:SnackBarService;
    private displayErrorSnackbar:boolean;

    constructor(http:HttpClient, snackBarService:SnackBarService) {
        this.http = http;
        this.snackBarService = snackBarService;
    }

    public get(url:string, options?:{}, callback?:Function | any, errorCallback?:Function) : Observable<ResponseModel> {
        return this.http.get(url, options).pipe(
            map((response:HttpResponse<any>) => {
                return this.getResponseModel(response, callback);
            }),
            catchError((error:HttpErrorResponse | any) => {
                return this.handleError(error, errorCallback);
            })
        );
    }

    public post(url:string, body?:any, options?:{}, callback?:Function | any, errorCallback?:Function) : Observable<ResponseModel> {
        return this.http.post(url, body, options).pipe(
            map((response:HttpResponse<any>) => {
                return this.getResponseModel(response, callback);
            }),
            catchError((error:HttpErrorResponse | any) => {
                return this.handleError(error, errorCallback);
            })
        );
    }

    /**
     * Returns a ResponseModel based on the Response, the callback can either be pure data or a ResponseModel
     * @param {HttpResponse<any>} response
     * @param {Function | any} callback
     * @returns {}
     */
    private getResponseModel(response:HttpResponse<any>, callback?:Function | any) : ResponseModel {
        let data:any;

        //Handle Callback
        if (typeof callback !== 'undefined' && callback !== null) {
            data = callback(response);

            //Should the callback return a ResponseModel instead
            if(data instanceof ResponseModel) {
                return data;
            }
        }
        else {
            //Check if a response object exists
            if(response && response['data']) {
                data = response['data'];
            }
            else if(response instanceof Blob) {
                data = response;
            }
            else {
                data = null;
            }
        }

        let responseModel = new ResponseModel({});
        responseModel.data = data;

        //Add Response Code
        if(response && response['code']) {
            responseModel.code = response['code'];
        }
        else {
            responseModel.code = ResponseModelCode.Ok;
        }

        //Add Error Message
        if(response && (response['errorMessage'])) {
            responseModel.errorMessage = response['errorMessage'];
        }

        return responseModel;
    }

    /**
     * Returns a ResponseModel based on the Error, the callback would return an error message or a ResponseModel
     * @param {HttpErrorResponse | any} error
     * @param {Function | any} errorCallBack
     * @returns {Observable<>}
     */
    public handleError(error:HttpErrorResponse | any, errorCallBack?:Function | any) : Observable<ResponseModel> {
        //Log Error
        console.error('ApiService::error', error);

        //Get the error message
        let errorMessage:string = error.message ? error.message : ResponseModelErrorMessages.Internal;

        //Display 5xx errors on the snackBar
        if((error.status === 500 || error.status === 503) &&
            (process.env['APP_DEPLOYMENT']['isDev'] ||
             process.env['APP_DEPLOYMENT']['isQa'] ||
             process.env['APP_DEPLOYMENT']['isStaging'] ||
             process.env['APP_DEPLOYMENT']['isDemo'])) {

            //Only ever display one snackbar for internal errors
            if(!this.displayErrorSnackbar) {
                this.displayErrorSnackbar = true;

                this.snackBarService.openAsError(ResponseModelErrorMessages.Internal).afterDismissed().subscribe(() => {
                    this.displayErrorSnackbar = false;
                });
            }
        }

        //Undefined error status
        if(error.status === 0) {
            return of(
                new ResponseModel({
                    code: ResponseModelCode.Error,
                    errorMessage: ResponseModelErrorMessages.Unknown
                })
            );
        }

        //Handle Error callback if we want to finetune the error message
        if(typeof errorCallBack !== 'undefined' && errorCallBack !== null) {
            let data = errorCallBack(error);

            if(data instanceof ResponseModel) {
                return of(data);
            }
        }

        //Some httpErrorResponse contains an inner error message, so use that instead
        if(error.error) {
            let innerErrorObject:any = error.error;

            if(innerErrorObject.code && innerErrorObject.errorMessage) {
                return of(new ResponseModel(innerErrorObject));
            }

            if(innerErrorObject.success === false && innerErrorObject.errorResult) {
                return of(
                    new ResponseModel({
                        code: ResponseModelCode.Error,
                        errorMessage: innerErrorObject.errorResult,
                    })
                );
            }
        }

        //All errors from the API now return from the subscribe, so using catch is not needed in the subscribe.
        return of(
            new ResponseModel({
                code: ResponseModelCode.Error,
                errorMessage: errorMessage,
            })
        );
    }
}