import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BrandModel } from '~/models/brandModel';
import { ClipModel } from '~/models/clipModel';
import { MobModel } from '~/models/mobModel';
import { LineModel } from '~/models/lineModel';
import { SessionService } from '~/services/storage/session/sessionService';
import { BaleModel } from '~/models/baleModel';
import { ESpeciPageService } from './services/especiService';
import { ESpeciPageRepository } from './repository/eSpeciRepository';
import { SpecieModel } from '~/models/specieModel';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { SettingsModel } from '~/models/settings/settingsModel';
import { ESpeciRouteValues } from './resolve/especiResolve';
import { BrandService } from '~/services/api/web/brand/brandService';
import { ClipService } from '~/services/api/web/clip/clipService';
import { AdminTemplateComponent } from '~/templates/admin/admin';
import { forkJoin, Subscription } from 'rxjs';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IntegrationService } from '~/services/api/web/integration/integrationService';
import { WoolClipModel } from '~/models/woolClipModel';
import { SnackBarService } from '~/components/snackBar/services/snackBarService';
import { AdminTemplateService } from '~/templates/admin/services/adminTemplateService';
import { EntityModel } from '~/models/entityModel';

export enum ESpeciViewState {
    SetupBrandView,
    SetupClipView,
    SpecieView
}

enum ESpeciTabsView {
    Overview,
    Mobs,
    Bales,
    Lines,
    Species
}

enum ESpeciStorageKeyNames {
    BrandId = 'app.pages.admin.especi.brandId',
    ClipId = 'app.pages.admin.especi.clipId',
    SpecieId = 'app.pages.admin.especi.specieId',
    LineId = 'app.pages.admin.especi.lineId',
    BaleId = 'app.pages.admin.especi.baleId',
    MobId = 'app.pages.admin.especi.mobId',
    TabIndex = 'app.pages.admin.especi.TabIndex',
}

@Component({
    templateUrl: './especi.html',
    host: {'class': 'page page--especi'},
    styleUrls: ['./especi.scss'],
    animations: [
        trigger('animate', [
            state('active', style({
                position: 'relative',
                transform: 'translate3d(0, 0, 0)'
            })),
            state('inactiveLeft', style({
                position: 'absolute',
                transform: 'translate3d(-100%, 0, 0)'
            })),
            state('inactiveRight', style({
                position: 'absolute',
                transform: 'translate3d(100%, 0, 0)'
            })),
            transition('* => active', animate('500ms ease-in-out')),
            transition('active => *', animate('500ms ease-in-out')),
        ])
    ]
})

export class ESpeciPageComponent {

    //Tab animation for outer tab
    public tabAnimationString:string[] = [];

    //Active tab for inner especi tab
    public activeTabIndex = null;

    //Session services
    private sessionService:SessionService;

    //Services
    private eSpeciService:ESpeciPageService;
    private brandService:BrandService;
    private integrationService:IntegrationService;
    private clipService:ClipService;
    private snackbarService:SnackBarService;

    //Repository
    public eSpeciRepository:ESpeciPageRepository;

    //ESpeci View State, whether setting up brand, clip or already on the specie page
    public ESpeciViewState: typeof ESpeciViewState = ESpeciViewState;
    public eSpeciViewState:ESpeciViewState;

    public currentBrand:BrandModel = null;
    public currentClip:ClipModel = null;

    public selectedSpecie:SpecieModel;
    public selectedLine:LineModel;
    public selectedBale:BaleModel;
    public selectedMob:MobModel;
    public clipItemsLoaded:boolean;

    public iconClipPath:string;
    public iconMobPath:string;
    public iconWoolBookPath:string;
    public iconLinePath:string;
    public iconSignPath:string;
    //public woolClipLinking:WoolClipModel;

    public selectedEntity: EntityModel;

    private changeDetectorRef:ChangeDetectorRef;
    private adminTemplateSelectedEntitySubscription: Subscription;
    private adminTemplateService: AdminTemplateService;
    private router: Router;


    constructor(eSpecieService:ESpeciPageService,
                sessionService:SessionService,
                brandService:BrandService,
                clipService:ClipService,
                router:Router,
                adminTemplateService: AdminTemplateService,
                activatedRoute: ActivatedRoute,
                changeDetectorRef:ChangeDetectorRef,
                integrationService:IntegrationService,
                snackbarService:SnackBarService) {

        this.sessionService = sessionService;
        this.brandService = brandService;
        this.clipService = clipService;
        this.eSpeciService = eSpecieService;
        this.adminTemplateService = adminTemplateService;
        this.changeDetectorRef = changeDetectorRef;
        this.integrationService = integrationService;
        this.snackbarService = snackbarService;
        this.router = router;

        this.adminTemplateSelectedEntitySubscription = this.adminTemplateService.getSelectedEntity().subscribe((entity: EntityModel) => {
            this.selectedEntity = entity;
        });

        this.loadTabIcons();

        if(this.router.url === '/dashboard/especi/new') {
            this.clearLocalValues();
            document.getElementById(AdminTemplateComponent.scrollContainerId).scrollTop = 0;
            this.router.navigate(['admin/especi']);
        }
        else if(this.router.url === '/dashboard/especi/overview') {
            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Overview);
            this.router.navigate(['admin/especi']);
        }
        else if(this.router.url === '/dashboard/especi/mobs') {
            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Mobs);
            this.router.navigate(['admin/especi']);
        }
        else if(this.router.url === '/dashboard/especi/bales') {
            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Bales);
            this.router.navigate(['admin/especi']);
        }
        else if(this.router.url === '/dashboard/especi/lines') {
            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Lines);
            this.router.navigate(['admin/especi']);
        }
        else if(this.router.url === '/dashboard/especi/specis') {
            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Species);
            this.router.navigate(['admin/especi']);
        }
        else {
            //Routing for individual items. examine especiResolve.ts
            let routeResolve = activatedRoute.snapshot.data['routeValues'];

            if(routeResolve) {
                routeResolve.subscribe((routeValues:ESpeciRouteValues) => {
                    //Values from resolve
                    if(routeValues) {
                        //Store selected values to sessions storage
                        this.sessionService.setNumber(ESpeciStorageKeyNames.BrandId, routeValues.brand.brandId);
                        this.sessionService.setNumber(ESpeciStorageKeyNames.ClipId, routeValues.clip.clipId);

                        if(routeValues.specie) {
                            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Species);
                            this.sessionService.setNumber(ESpeciStorageKeyNames.SpecieId, routeValues.specie.specieId);
                        }
                        else if(routeValues.line) {
                            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Lines);
                            this.sessionService.setNumber(ESpeciStorageKeyNames.LineId, routeValues.line.lineId);
                        }
                        else if(routeValues.bale) {
                            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Bales);
                            this.sessionService.setNumber(ESpeciStorageKeyNames.BaleId, routeValues.bale.baleId);
                        }
                        else if(routeValues.mob) {
                            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Mobs);
                            this.sessionService.setNumber(ESpeciStorageKeyNames.MobId, routeValues.mob.mobId);
                        }
                        else {
                            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Overview);
                        }
                    }
                    else {
                        this.clearLocalValues();
                    }

                    //Redirect to especi
                    router.navigate(['admin/especi']);
                });
            }
            else {
                let localBrandId:number = this.sessionService.getNumber(ESpeciStorageKeyNames.BrandId);
                let localClipId:number = this.sessionService.getNumber(ESpeciStorageKeyNames.ClipId);


                if(localBrandId && localClipId) {
                    let brandService = this.brandService.getBrandById(localBrandId);
                    let clipService = this.clipService.getClipById(localClipId);

                    //Load Brand and Clip before going to the specie view state
                    forkJoin([brandService, clipService]).subscribe((results:ResponseModel[]) => {
                        let brandResult:ResponseModel = results[0];
                        let clipResult:ResponseModel = results[1];

                        if(brandResult.code === ResponseModelCode.Error || clipResult.code === ResponseModelCode.Error) {
                            this.currentClip = null;
                            this.currentBrand = null;
                            this.displaySetupBrandView();
                            return;
                        }

                        this.currentBrand = brandResult.data;
                        //this.getWoolClipLinkingCode();
                        this.currentClip = clipResult.data;

                        let localTabIndex = this.sessionService.getNumber(ESpeciStorageKeyNames.TabIndex);

                        this.activeTabIndex = localTabIndex ? localTabIndex : ESpeciTabsView.Overview;

                        this.eSpeciRepository = new ESpeciPageRepository();
                        this.eSpeciRepository.setBrand(this.currentBrand);
                        this.eSpeciRepository.setClip(this.currentClip);

                        //Is there a brand and clip already set?
                        if(this.eSpeciRepository.isClipNotEmpty() && this.eSpeciRepository.isBrandNotEmpty()) {
                            this.eSpeciViewState = ESpeciViewState.SpecieView;
                            this.tabAnimationString = ['inactiveLeft', 'active'];
                            this.setClipItems();
                        }
                    });
                }
                else if(localBrandId) {
                    this.brandService.getBrandById(localBrandId).subscribe((response:ResponseModel) => {
                        if(response.code === ResponseModelCode.Ok) {
                            this.currentBrand = response.data;
                            //this.getWoolClipLinkingCode();
                        }
                        else if(response.code === ResponseModelCode.Error) {
                            this.currentBrand = null;
                        }

                        this.eSpeciRepository = new ESpeciPageRepository();
                        this.eSpeciRepository.setBrand(this.currentBrand);
                        //Is there a brand set?
                        if(this.eSpeciRepository.isBrandNotEmpty()) {
                            this.eSpeciViewState = ESpeciViewState.SetupClipView;
                            this.tabAnimationString = ['active', 'inactiveRight'];
                        }
                    });
                }
                else {
                    this.eSpeciRepository = new ESpeciPageRepository();
                    this.eSpeciViewState = ESpeciViewState.SetupBrandView;
                    this.tabAnimationString = ['active', 'inactiveRight'];
                }
            }
        }
    }

    public onConnectToWoolClipClicked(): void {
        if(this.selectedEntity && this.selectedEntity.entityId) { 
            this.router.navigate(['/dashboard/business/' + this.selectedEntity.entityId ], { queryParams: { tab : 'Brands/Users'}});
        } else {    
            this.router.navigate([`/admin/business`]);
        }
    }

    /**
     * Loads the tab icons
     */
    private loadTabIcons() : void {
        this.iconClipPath = require('~/assets/images/templates/admin/tabIcons/icon-clip.svg');
        this.iconMobPath = require('~/assets/images/templates/admin/tabIcons/icon-mob.svg');
        this.iconWoolBookPath = require('~/assets/images/templates/admin/tabIcons/icon-woolbook.svg');
        this.iconLinePath = require('~/assets/images/templates/admin/tabIcons/icon-line.svg');
        this.iconSignPath = require('~/assets/images/templates/admin/tabIcons/icon-sign.svg');
    }

    /**
     * Set the clip related items
     */
    private setClipItems() : void {
        this.clipItemsLoaded = false;
        this.eSpeciService.getESpeciClipData(this.eSpeciRepository.getCurrentClip()).subscribe((results:ResponseModel[]) => {

            let mobResult:ResponseModel = results[0];
            let baleResult:ResponseModel = results[1];
            let lineResult:ResponseModel = results[2];
            let specieResult:ResponseModel = results[3];
            let woolDescriptionResult:ResponseModel = results[4];
            let binCodeResult:ResponseModel = results[5];
            this.clipItemsLoaded = true;

            if (mobResult.code === ResponseModelCode.Ok &&
                baleResult.code === ResponseModelCode.Ok &&
                lineResult.code === ResponseModelCode.Ok &&
                specieResult.code === ResponseModelCode.Ok &&
                woolDescriptionResult.code === ResponseModelCode.Ok &&
                binCodeResult.code === ResponseModelCode.Ok) {

                let mobs:MobModel[] = mobResult.data;
                let bales:BaleModel[] = baleResult.data;
                let lines:LineModel[] = lineResult.data;
                let species:SpecieModel[] = specieResult.data;
                let woolDescriptionSettings:SettingsModel = woolDescriptionResult.data;
                let binCodeSettings:SettingsModel = binCodeResult.data;

                this.eSpeciRepository.setClipItems(mobs, bales, lines, species);
                this.eSpeciRepository.setClipSettings(binCodeSettings, woolDescriptionSettings);

                let specieId:number = this.sessionService.getNumber(ESpeciStorageKeyNames.SpecieId);
                let lineId:number  = this.sessionService.getNumber(ESpeciStorageKeyNames.LineId);
                let baleId:number  = this.sessionService.getNumber(ESpeciStorageKeyNames.BaleId);
                let mobId:number  = this.sessionService.getNumber(ESpeciStorageKeyNames.MobId);

                if(specieId) {
                    this.selectedSpecie = this.eSpeciRepository.getSpecieById(specieId);
                }
                else if(lineId) {
                    this.selectedLine = this.eSpeciRepository.getLineById(lineId);
                }
                else if(baleId) {
                    this.selectedBale = this.eSpeciRepository.getBaleById(baleId);
                }
                else if(mobId) {
                    this.selectedMob = this.eSpeciRepository.getMobById(mobId);
                }

                this.sessionService.remove(ESpeciStorageKeyNames.SpecieId);
                this.sessionService.remove(ESpeciStorageKeyNames.LineId);
                this.sessionService.remove(ESpeciStorageKeyNames.BaleId);
                this.sessionService.remove(ESpeciStorageKeyNames.MobId);

                this.changeDetectorRef.detectChanges();
            }
        });
    }


    public clipDisabled(): void {
        this.displaySetupBrandView();
    }

    /**
     * Displays the setup brand view screen
     */
    public displaySetupBrandView() : void {
        //Empty out the clip
        if(this.eSpeciRepository) {
            this.eSpeciRepository.setClip(new ClipModel({ clipId: 0}));
        }
        this.eSpeciViewState = ESpeciViewState.SetupBrandView;
        this.tabAnimationString = ['active', 'inactiveRight'];
        this.clearLocalValues();

        setTimeout(() => {
            this.deselectSelectedValues();
        }, 500);
    }

    /**
     * Displays the setup clip viewScreen
     */
    public displaySetupClipView() {
        //Empty out the clip
        if(this.eSpeciRepository) {
            this.eSpeciRepository.setClip(new ClipModel({ clipId: 0}));
        }
        this.eSpeciViewState = ESpeciViewState.SetupClipView;
        this.tabAnimationString = ['active', 'inactiveRight'];

        // Remove tabindex and clip id only!
        this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Overview);
        this.sessionService.remove(ESpeciStorageKeyNames.ClipId);

        setTimeout(() => {
            this.deselectSelectedValues();
        }, 500);
    }

    /**
     * Uses the brand that is selected in the brand dropdown for the select brand
     * This is used to display an unclaimed brand message in the especi page
     * @param {} brand
     */
    public brandSelectingEvent(brand:BrandModel) : void {
        this.currentBrand = brand;
        //this.getWoolClipLinkingCode();
    }

    /**
     * Grabs the current brand selected and goes to the clip view state
     * @param brand
     */
    public brandSelectedEvent(brand:BrandModel) : void {
        this.currentBrand = brand;
        //this.getWoolClipLinkingCode();
        this.sessionService.setNumber(ESpeciStorageKeyNames.BrandId, brand.brandId);
        this.eSpeciViewState = ESpeciViewState.SetupClipView;
    }

    /**
     * Grabs the current brand selected and goes to the specie view state
     * @param clip
     */
    public clipSelectedEvent(clip:ClipModel) : void {
        this.currentClip = clip;
        this.sessionService.setNumber(ESpeciStorageKeyNames.ClipId, clip.clipId);

        this.tabAnimationString = ['inactiveLeft', 'active'];
        setTimeout(() => {
            this.eSpeciViewState = ESpeciViewState.SpecieView;
        }, 500);

        this.setClipItems();
        this.activeTabIndex = ESpeciTabsView.Overview; //Go to Overview
    }

    public currentBrandEvent(brand:BrandModel) : void {
        this.currentBrand = brand;
        //this.getWoolClipLinkingCode();
        this.sessionService.setNumber(ESpeciStorageKeyNames.BrandId, brand.brandId);
    }

    /**
     * Updates the clip
     * @param clip
     */
    public currentClipEvent(clip:ClipModel) : void {
        this.currentClip = clip;
        this.sessionService.setNumber(ESpeciStorageKeyNames.ClipId, clip.clipId);
    }

    /**
     * Goes to the Mob View
     * @param currentMob
     */
    public goToMobsTab() : void {
        this.activeTabIndex = ESpeciTabsView.Mobs;
    }

    /**
     * Grabs a bale from a child view and goes to the bale tab
     * @param currentLine
     */
    public goToBalesTab() : void {
        this.activeTabIndex = ESpeciTabsView.Bales;
    }

    /**
     * Grabs a line from a child view and goes to the line tab
     * @param currentLine
     */
    public goToLinesTab() : void {
        this.activeTabIndex = ESpeciTabsView.Lines;
    }

    /**
     * Grabs a specie from a child view and goes to the specie tab
     */
    public goToSpeciesTab() : void {
        this.activeTabIndex = ESpeciTabsView.Species;
    }

    /**
     * Sets the selected mob
     * @param mob
     */
    public setSelectedMobEvent(mob: MobModel) : void {
        this.selectedMob = mob;
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Sets the selected bale
     * @param bale
     */
    public setSelectedBaleEvent(bale: BaleModel) : void {
        this.selectedBale = bale;
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Sets the selected line
     * @param line
     */
    public setSelectedLineEvent(line: LineModel) : void {
        this.selectedLine = line;
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Sets the selected specie
     * @param specie
     */
    public setSelectedSpecieEvent(specie: SpecieModel) : void {
        this.selectedSpecie = specie;
        this.changeDetectorRef.detectChanges();
    }

    /**
     * Deselects the selected line
     */
    public deselectBaleEvent() : void {
        this.selectedBale = null;
        this.changeDetectorRef.detectChanges();
        this.scrollToTop();
    }

    /**
     * Deselects the selected mob
     */
    public deselectMobEvent() : void {
        this.selectedMob = null;
        this.changeDetectorRef.detectChanges();
        this.scrollToTop();
    }

    /**
     * Deselects the selected line
     */
    public deselectLineEvent() : void {
        this.selectedLine = null;
        this.changeDetectorRef.detectChanges();
        this.scrollToTop();
    }

    /**
     * Deselects the selected specie
     */
    public deselectSpecieEvent() : void {
        this.selectedSpecie = null;
        this.changeDetectorRef.detectChanges();
        this.scrollToTop();
    }

    /**
     * Sets the active tab for the especi page
     * @param activeTabIndex
     */
    public activeTabIndexEvent(activeTabIndex:number) : void {
        if(this.activeTabIndex !== null) {
            this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, activeTabIndex);
        }

        if(this.clipItemsLoaded) {
            this.deselectSelectedValues();
        }
    }

    public deselectSelectedValues() : void {
        //Deselect values
        this.deselectBaleEvent();
        this.deselectLineEvent();
        this.deselectSpecieEvent();
        this.deselectMobEvent();
    }

    // private getWoolClipLinkingCode(): void {
    //     if(!this.currentBrand || (this.currentBrand.brandId || 0) < 1) {
    //         return;
    //     }

    //     this.integrationService.getLinkingCode(this.currentBrand.brandId).subscribe((response:ResponseModel) => {
    //         if(response.code === ResponseModelCode.Ok) {
    //             this.woolClipLinking = response.data;
        
    //         }
    //         if (response.code === ResponseModelCode.Error) {
    //             this.snackbarService.open('Error:' + response.errorMessage);
    //         }
    //     }, (_error) => {
    //         this.snackbarService.open('Error: Could not generate linking code');
    //     })
    // }

    /**
     * Clears the local storage values when the user wants to start a new especi.
     */
    private clearLocalValues() : void {
        this.sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Overview);
        this.sessionService.remove(ESpeciStorageKeyNames.BrandId);
        this.sessionService.remove(ESpeciStorageKeyNames.ClipId);
    }

    /**
     * Static version for clearing the session storage. Used from the auth service
     */
    public static clearLocalValues() : void {
        let sessionService = new SessionService();

        sessionService.setNumber(ESpeciStorageKeyNames.TabIndex, ESpeciTabsView.Overview);
        sessionService.remove(ESpeciStorageKeyNames.BrandId);
        sessionService.remove(ESpeciStorageKeyNames.ClipId);
    }

    private scrollToTop() : void {
        document.getElementById('page-tpl__main').scrollTop = 0;
    }
}
