import { Injectable, Injector } from '@angular/core';

// Models
import { BrandModel } from '~/models/brandModel';
import { EntityModel } from '~/models/entityModel';
import { ResponseModel, ResponseModelCode } from '~/models/responseModel';
import { UserModel } from '~/models/userModel';
import { UserPreferenceModel } from '~/models/userPreferenceModel';

// Services
import { AccountService } from '~/services/api/web/account/accountService';
import { BrandService } from '~/services/api/web/brand/brandService';
import { EntityService } from '~/services/api/web/entity/entityService';
import { LocalService } from '~/services/storage/local/localService';
import { AuthLocalStorageKeyNames, AuthService } from '~/services/api/auth/authService';
import { SnackBarService } from '~/components/snackBar/services/snackBarService';
import { UserPreferenceLocalService, UserPreferenceKeyNames } from '~/services/api/web/userPreference/userPreferenceLocalService';

// Rxjs
import { takeWhile, mergeMap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of } from 'rxjs';

import { find as _find, remove as _remove, isNil as _isNil, startsWith as _startsWith } from 'lodash';
import { TradingRegistationStatus } from '~/models/tradingRegistrationModel';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AdminTemplateService {

    private userSubject:BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
    private userCanTradeSubject:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
    private entityTradingStatus:BehaviorSubject<TradingRegistationStatus> = new BehaviorSubject<TradingRegistationStatus>(undefined);
    private entitiesSubject:BehaviorSubject<EntityModel[]> = new BehaviorSubject<EntityModel[]>(undefined);
    private selectedEntitySubject:BehaviorSubject<EntityModel> = new BehaviorSubject<EntityModel>(null);
    private selectedBrandSubject:BehaviorSubject<BrandModel> = new BehaviorSubject<BrandModel>(null);

    private accountService:AccountService;
    private entityService:EntityService;
    private brandService:BrandService;
    private snackBarService:SnackBarService;
    private userPreferenceLocalService:UserPreferenceLocalService;
    private localStorage:LocalService;
    private authService:AuthService;
    private route:ActivatedRoute;

    constructor(accountService:AccountService,
                entityService:EntityService,
                brandService:BrandService,
                snackBarService:SnackBarService,
                userPreferenceLocalService:UserPreferenceLocalService,
                localStorage: LocalService,
                route:ActivatedRoute,injector:Injector,) {
        this.route= route;
        this.authService = injector.get(AuthService);
        this.accountService = accountService;
        this.entityService = entityService;
        this.brandService = brandService;
        this.snackBarService = snackBarService;
        this.userPreferenceLocalService = userPreferenceLocalService;
        this.localStorage = localStorage;

        let activatedRoute = this.route.snapshot;
        let isVisitingPublicPage:boolean = false;
        if(activatedRoute['_routerState'] && activatedRoute['_routerState']['url']) {
            isVisitingPublicPage =  _startsWith( activatedRoute['_routerState']['url'], '/profile/') ||
            activatedRoute['_routerState']['url'] === '/readyReckoner';
        }

        if(!isVisitingPublicPage) { 
            this.triggerLoad();
        }
        else {
            this.authService.hasValidAuthToken().then((isValid) => {
                if (isValid){
                    this.triggerLoad();
                }
            })
        }
    }

    private triggerLoad(): void{
        this.loadUser();
        this.loadEntities();
    }

    /**
     * Load user once onload for adminTemplateService
     */
    public loadUser() : void {
        this.accountService.getProfile().subscribe((response:ResponseModel) => {
            if(response.code === ResponseModelCode.Ok) {
                this.setUser(response.data);
            }
        });
    }

    /**
     * Retrieves the current logged in User
     * @returns {Observable<UserModel>}
     */
    public getUser() : Observable<UserModel> {
        return this.userSubject.asObservable();
    }

    /**
     * Sets the current logged in User
     * @param {} user
     */
    public setUser(user:UserModel) : void {
        this.userSubject.next(user);
    }

    /**
     * Remove the current logged in User
     */
    public removeUser() : void {
        this.userSubject.next(null);
    }

    public loadEntities() : void {
        this.entityService.getAll().subscribe((response:ResponseModel) => {
            if(response.code === ResponseModelCode.Ok) {
                let entities:EntityModel[] = response.data;
                this.entitiesSubject.next(entities);

                this.userPreferenceLocalService.getNumberValue(UserPreferenceKeyNames.App.Templates.Admin.SelectedEntity).subscribe((userPref:UserPreferenceModel<number>) => {
                    if(!_isNil(userPref)) {
                        if(_isNil(userPref.value) && entities.length > 0) {
                            this.setSelectedEntity(entities[0]);
                            return;
                        }

                        let entityId:number = userPref.value;
                        let entity:EntityModel = _find(entities, (entity:EntityModel) => entity.entityId === entityId);

                        if(entity) {
                            this.setSelectedEntity(entity);
                        }
                        else if(entities.length > 0) {
                            this.setSelectedEntity(entities[0]);
                        }
                        else {
                            this.removeSelectedEntity();
                        }
                    }
                });
            }
        });
    }

    public loadBrandsByEntity(entity:EntityModel, editPermission:boolean) : Observable<BrandModel[]> {
        return this.brandService.getBrandsByEntity(entity, editPermission).pipe(
            mergeMap((response:ResponseModel) => {
                if (response.code === ResponseModelCode.Ok) {
                    let brands: BrandModel[] = !_isNil(response.data) ? response.data : [];

                    return this.userPreferenceLocalService.getNumberValue(UserPreferenceKeyNames.App.Templates.Admin.SelectedBrand).pipe(
                        map((userPreference:UserPreferenceModel<number>) => {
                            if(!_isNil(userPreference) && !_isNil(userPreference.value)) {
                                let brandId:number = +userPreference.value;

                                //Set the brand stored in the userPreferences
                                if (brandId) {
                                    let brand: BrandModel = _find(brands, (brand: BrandModel) => {
                                        return brand.brandId === brandId;
                                    });

                                    if (brand) {
                                        this.setSelectedBrand(brand);
                                    }
                                    else {
                                        this.removeSelectedBrand();
                                    }
                                }
                            }
                            return brands;
                        })
                    );
                }
                else if(response.code === ResponseModelCode.Error) {
                    this.snackBarService.openAsError(response.errorMessage);
                }
                return [];
            })
        );
    }

    public setSelectedBrand(selectedBrand:BrandModel) : void {

        this.userPreferenceLocalService.postNumberValue(UserPreferenceKeyNames.App.Templates.Admin.SelectedBrand, selectedBrand.brandId)
            .subscribe((userPreference:UserPreferenceModel<number>) => {
                if(!_isNil(userPreference) && !_isNil(userPreference.value)) {
                    this.selectedBrandSubject.next(selectedBrand);
                }
            }
        );
    }

    public getSelectedBrand() : Observable<BrandModel> {
        return this.selectedBrandSubject.asObservable();
    }

    /**
     * Inserts the entity in entities and sets the selected entity if the entities list is empty
     * @param {} entity
     */
    public insertEntity(entity:EntityModel) : void {
        let entityListUpdated = false;
        this.getEntities().pipe(
            takeWhile(() => {
                return !entityListUpdated;
            })
        ).subscribe((entities) => {
            //Inserts the entity to the entities list
            let newEntities:EntityModel[] = entities || [];
            newEntities.push(entity);
            entityListUpdated = true;

            //Makes the entity selected when it is the only entity
            if(newEntities.length === 1) {
                this.setSelectedEntity(entity);
            }

            //Updates the entitiesSubject
            this.entitiesSubject.next(newEntities);
        });
    }

    /**
     * Removes the entity from entities and sets the selectec entity to another entity or null
     * @param {} entityToRemove
     */
    public removeEntity(entityToRemove:EntityModel) : void {
        let entityListUpdated = false;
        let selectedEntityRemoved = false;

        this.getEntities().pipe(
            takeWhile(() => {
                return !entityListUpdated;
            })
        ).subscribe((entities) => {
            //Removes the entityToRemove from the entities list
            let newEntities:EntityModel[] = entities;
            _remove(newEntities, (entity:EntityModel) => {
                return entity.entityId === entityToRemove.entityId;
            });
            entityListUpdated = true;

            //Replaces or removes the selectedEntity
            this.getSelectedEntity().pipe(
                takeWhile(() => {
                    return !selectedEntityRemoved;
                })
            ).subscribe((selectedEntity) => {
                selectedEntityRemoved = true;
                if(selectedEntity.entityId === entityToRemove.entityId) {
                    if(newEntities.length > 0) {
                        //Set the last most entity
                        this.setSelectedEntity(newEntities[newEntities.length - 1]);
                    }
                    else {
                        this.removeSelectedEntity();
                    }
                }
            });

            //Updates the entitiesSubject
            this.entitiesSubject.next(newEntities);
        });
    }

    public setSelectedEntity(selectedEntity:EntityModel) : void {
        // This isn't the most robust way to get the selected entity guid to be used in the authInterceptor
        // This SelectedEntity logic ideally could do with a more reliable & persistent state management system
        this.localStorage.set(AuthLocalStorageKeyNames.EntityGuid, selectedEntity.guid);
        
        this.userPreferenceLocalService.postNumberValue(UserPreferenceKeyNames.App.Templates.Admin.SelectedEntity, selectedEntity.entityId)
            .subscribe((userPreference:UserPreferenceModel<number>) => {
                if(!_isNil(userPreference) && !_isNil(userPreference.value)) {
                    this.selectedEntitySubject.next(selectedEntity);

                    this.accountService.postSelectedTradingEntityId(selectedEntity.entityId).subscribe((tradingResponse:ResponseModel) => {
                        if(tradingResponse.code === ResponseModelCode.Ok) {
                            let data = tradingResponse.data ? true : false;
                            this.userCanTradeSubject.next(data);
                        }
                        else if(tradingResponse.code === ResponseModelCode.Error) {
                            this.snackBarService.openAsError(tradingResponse.errorMessage);
                        }
                    });
                }
            }
        );
    }

    public userCanTrade() : Observable<boolean> {
        return this.userCanTradeSubject.asObservable();
    }

    public setUserCanTrade(canTrade:boolean) : void {
         this.userCanTradeSubject.next(canTrade);
    }

    public getEntities() : Observable<EntityModel[]> {
        return this.entitiesSubject.asObservable();
    }

    public getSelectedEntity() : Observable<EntityModel> {
        return this.selectedEntitySubject.asObservable();
    }

    public getSelectedEntityOf() : Observable<EntityModel> {
        return this.selectedEntitySubject as Observable<EntityModel>;
    }

    public removeSelectedEntity() : void {

        this.userPreferenceLocalService.deleteValue(UserPreferenceKeyNames.App.Templates.Admin.SelectedEntity).subscribe((deleted:boolean) => {
            if(deleted) {
                this.selectedEntitySubject.next(null);
                this.userCanTradeSubject.next(false);
            }
        });
    }

    public removeSelectedBrand() : void {
        this.userPreferenceLocalService.deleteValue(UserPreferenceKeyNames.App.Templates.Admin.SelectedBrand).subscribe((deleted:boolean) => {
            if(deleted) {
                this.selectedBrandSubject.next(null);
            }
        });
    }
}
