import { BaleModel } from '~/models/baleModel';
import { LotTestResultModel } from '~/models/lotTestResultModel';
import { SalesResultModel } from '~/models/salesResultModel';
import { Moment } from '~/decorators/moment/momentDecorator';
import * as moment from 'moment';

export class InventoryBaleModel extends BaleModel {
    public seller:string;
    public description:string;
    public specieId:number;

    @Moment()
    public startDate:moment.Moment;
    public testResult:LotTestResultModel;
    public salesResult:SalesResultModel;

    constructor(values: Object = {}) {
        super(values);
    }
}
