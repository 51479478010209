import { BaseModel } from '~/models/baseModel';

export class AdminPendingConnectionsModel extends BaseModel {
   public brandId: number;
   public brandName: string;
   public brandPartnerId: number;
   public entityName: string;
   public requestDate: string;
   public sellerEntityId: number;
   public sellerName: string;
   public source: string;
   public brandAdmins:Person[];
   public requestedBy:Person[];
   public sellerAdmins: Person[];
}
export class Person extends BaseModel {
   public contactId: number;
   public email: string;
   public fullName:string;
   public firstName:string;
   public lastName:string;
}