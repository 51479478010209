import { BaseModel } from '~/models/baseModel';
import { OfferLotLimitModel } from '~/models/offerLotLimitModel';
import { map as _map } from 'lodash';

export class OfferLotModel extends BaseModel {
    public lotId:number;
    public lotNo:string;
    public description:string;
    public bales:number;
    public brand:string;
    public saleId:string;
    public sellingCenter:string;
    public saleNumber:string;
    public cvh:number;
    public awexId:string;
    public micron:number;
    public vm:number;
    public yield:number;
    public len:number;
    public nkt:number;
    public pob:number;
    public pobm:number;
    public mm:number;
    public mmh:number;
    public cert2:string;
    public cert1:string;
    public grsyWeight:number;
    public awexVerified:boolean;
    public clnWeight:number;
    public limit:OfferLotLimitModel[];
    public selectedLimit:OfferLotLimitModel; //Used in the auctionTable

    //Expanded Details
    public seller:string;
    public wooltype:string;
    public ct_vm1:number;
    public ct_vm2:number;
    public ct_vm3:number;
    public ct_wbase:number;
    public acy:number;
    public sdr1:number;
    public ct_slencv:number;
    public ct_pobt:number;
    public ct_pobb:number;
    public ct_haut:number;
    public dmfr:string;
    public l2mules:string;
    public ct_lscvm:number;
    public ct_lssdm:number;
    public ct_lscomft:number;
    public ct_lscurmn:number;
    public jcsy:number;
    public sdr17:number;
    public sdr16:number;
    public lh_pcode:number;
    public lh_porg:number;
    public lh_code:string;
    public stcenter:string;
    public storageCenter:string;
    public buyerType:string;
    public buyerComment:string;
    public ct_str:number;
    public qScheme1:string;

    constructor(values: Object = {}) {
        super(values);
        if(values['limit']) {
            this.limit = _map(values['limit'], (limit) => {
                return new OfferLotLimitModel(limit);
            });
        }
    }
}
