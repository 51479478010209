import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

//WEP Components
import { TabComponent } from '~/components/tabs/tab/tab';
import { TabsComponent } from '~/components/tabs/tabs';
import { SquareBlockComponent } from '~/components/square/squareBlock/squareBlock';
import { SquareButtonComponent } from '~/components/square/squareButton/squareButton';
import { SquareInputTextComponent } from '~/components/square/squareInput/squareInputText/squareInputText';
import { SquareInputButtonComponent } from '~/components/square/squareInput/squareInputButton/squareInputButton';
import { SquareInputButtonVariantComponent } from '~/components/square/squareInput/squareInputButton/squareInputButtonVariant/squareInputButtonVariant';
import { SquareInputToggleComponent } from '~/components/square/squareInput/squareInputToggle/squareInputToggle';
import { SquareGridComponent } from '~/components/squareGrid/squareGrid';
import { SquareGridSmallComponent } from '~/components/squareGrid/squareGridSmall/squareGridSmall';
import { UploadCertificateComponent } from '~/components/upload/uploadCertificate/uploadCertificate';
import { UploadImageComponent } from '~/components/upload/uploadImage/uploadImage';
import { UploadVideoComponent } from '~/components/upload/uploadVideo/uploadVideo';
import { UploadGalleryComponent } from '~/components/upload/uploadGallery/uploadGallery';
import { DatePickerComponent } from '~/components/datepicker/datepicker';
import { SignaturePadComponent } from '~/components/signaturePad/signaturePad';
import { ChipsComponent } from '~/components/chips/chips';
import { ColorSelectComponent } from '~/components/colorSelect/colorSelect';
import { ColorOptionComponent } from '~/components/colorSelect/colorOption/colorOption';
import { ButtonComponent } from '~/components/button/button';
import { ButtonGroupComponent } from '~/components/buttonGroup/buttonGroup';
import { ButtonRadioComponent } from '~/components/buttonRadio/buttonRadio';
import { ButtonRadioOptionComponent } from '~/components/buttonRadio/buttonRadioOption/buttonRadioOption';
import { FilterButtonComponent } from '~/components/filterButton/filterButton';
import { FilterButtonGroupComponent } from '~/components/filterButtonGroup/filterButtonGroup';
import { SnackBarComponent } from '~/components/snackBar/snackBar';
import { SearchComponent } from '~/components/search/search';
import { SelectComponent } from '~/components/select/select';
import { OptionComponent } from '~/components/select/option/option';
import { SelectMenuComponent } from '~/components/selectMenu/selectMenu';
import { MenuOptionComponent } from '~/components/selectMenu/menuOption/menuOption';
import { SelectPriceComponent } from '~/components/selectPrice/selectPrice';
import { SliderComponent } from '~/components/slider/slider';
import { SnackBarService } from '~/components/snackBar/services/snackBarService';
import { CarouselComponent } from '~/components/carousel/carousel';
import { CarouselItemComponent } from '~/components/carousel/carouselItem/carouselItem';
import { PriceBoxComponent } from '~/components/priceBox/priceBox';
import { InsightBigTextComponent } from '~/components/insights/bigText/bigText';
import { InsightTitleComponent } from '~/components/insights/title/title';
import { ContentBoxComponent } from '~/components/contentBox/contentBox';
import { PasswordComponent } from '~/components/password/password';
import { AwexListComponent } from '~/components/awexList/awexList';
import { StepperComponent } from '~/components/stepper/stepper';
import { StepComponent } from '~/components/stepper/step/step';
import { PaginationComponent } from '~/components/pagination/pagination';
import { AgGridComponent } from '~/components/agGrid/agGrid';
import { AgGridDirective } from '~/components/agGrid/directives/agGridDirective';
import { AgGridCellRendererActionComponent } from '~/components/agGrid/cellRenderers/action/action';
import { AgGridCellRendererAvatarUserComponent } from '~/components/agGrid/cellRenderers/avatarUser/avatarUser';
import { AgGridCellRendererBarComponent } from '~/components/agGrid/cellRenderers/bar/bar';
import { AgGridCellRendererCheckboxComponent } from '~/components/agGrid/cellRenderers/checkbox/checkbox';
import { AgGridHeaderCheckboxComponent } from '~/components/agGrid/cellRenderers/checkbox-header/checkbox-header';
import { AgGridCellRendererIconComponent } from '~/components/agGrid/cellRenderers/icon/icon';
import { AgGridCellRendererLinkComponent } from '~/components/agGrid/cellRenderers/link/link';
import { AgGridCellRendererRadioComponent } from '~/components/agGrid/cellRenderers/radio/radio';
import { AgGridCellEditorDatePickerComponent } from '~/components/agGrid/cellEditors/datepicker/datepicker';
import { AgGridCellEditorInputComponent } from '~/components/agGrid/cellEditors/input/input';
import { AgGridCellEditorInputCurrencyComponent } from '~/components/agGrid/cellEditors/input/inputCurrency/inputCurrency';
import { AgGridCellEditorInputCurrencyCentsComponent } from '~/components/agGrid/cellEditors/input/inputCurrency/inputCurrencyCents/inputCurrencyCents';
import { AgGridCellEditorInputNumberComponent } from '~/components/agGrid/cellEditors/input/inputNumber/inputNumber';
import { AgGridCellEditorInputReadonlyComponent } from '~/components/agGrid/cellEditors/input/inputReadonly/inputReadonly';
import { AgGridNoRowsOverlayComponent } from '~/components/agGrid/overlays/noRowsOverlay';
import { AgGridCellRendererMarketAuctionButtonComponent } from '~/components/agGrid/cellRenderers/admin/market/auction/button/button';
import { AgGridCellRendererMarketAuctionDetailsComponent } from '~/components/agGrid/cellRenderers/admin/market/auction/details/details';
import { AgGridCellRendererMarketAuctionStickyComponent } from '~/components/agGrid/cellRenderers/admin/market/auction/sticky/sticky';
import { AgGridCellRendererMarketAuctionStatusComponent } from '~/components/agGrid/cellRenderers/admin/market/auction/status/status';
import { AgGridCellRendererMarketAuctionPlainComponent } from '~/components/agGrid/cellRenderers/admin/market/auction/plain/plain';
import { AgGridCellRendererMarketAuctionLimitSelectComponent } from '~/components/agGrid/cellRenderers/admin/market/auction/limitSelect/limitSelect';
import { AgGridCellRendererMarketAdminAuctionStatusComponent } from '~/components/agGrid/cellRenderers/admin/market/admin/auction/status/status';
import { AgGridCellRendererMarketAdminTenderStatusComponent } from '~/components/agGrid/cellRenderers/admin/market/admin/tender/status/status';
import { AgGridCellRendererMarketOrderBookStatusComponent } from '~/components/agGrid/cellRenderers/admin/market/orderBook/status/status';
import { GalleryComponent } from '~/components/gallery/gallery';
import { GalleryItemComponent } from '~/components/gallery/galleryItem/galleryItem';
import { GalleryOverlayComponent } from '~/components/gallery/galleryOverlay/galleryOverlay';
import { ImageComponent } from '~/components/image/image';
import { EmptyComponent } from '~/components/states/empty/empty';
import { LoadingComponent } from '~/components/states/loading/loading';
import { ListComponent } from '~/components/list/list';
import { ListItemComponent } from '~/components/list/listItem/listItem';
import { ListRowComponent } from '~/components/list/listRow/listRow';
import { ListCellComponent } from '~/components/list/listCell/listCell';
import { PostEditorComponent } from '~/components/postEditor/postEditor';
import { NotificationComponent, NotificationDirective } from '~/components/notification/notification';
import { NotificationErrorComponent } from '~/components/notification/notificationError/notificationError';
import { AvatarComponent } from '~/components/avatar/avatar';
import { AvatarUserComponent } from '~/components/avatar/avatarUser/avatarUser';
import { AutocompleteComponent } from '~/components/autocomplete/autocomplete';
import { BoxComponent } from '~/components/box/box';
import { FaqItemComponent } from '~/components/faqItem/faqItem';
import { DialogTooltipComponent } from '~/components/dialogTooltip/dialogTooltip';
import { traceabilitySummaryComponent } from '~/components/traceabilitySummary/traceabilitySummary';
import { DataSharingInfoBoxComponent } from '~/components/dataSharingInfoBox/dataSharingInfoBox';

import { FeedItemComponent } from '~/components/feedItem/feedItem';
import { DeletePostDialog } from '~/components/feedItem/dialogs/deletePostDialog';
import { DeletePostCommentDialog } from '~/components/feedItem/dialogs/deletePostCommentDialog';

// Charts
import { BarChartComponent } from '~/components/charts/barChart/barChart';
import { PieChartComponent } from '~/components/charts/pieChart/pieChart';
import { LineChartComponent } from '~/components/charts/lineChart/lineChart';
import { MultiSymbolChartComponent } from '~/components/charts/multiSymbolChart/multiSymbolChart';
import { LineAreaChartComponent } from '~/components/charts/lineAreaChart/lineAreaChart';
import { MultiBarChartComponent } from '~/components/charts/multiBarChart/multiBarChart';
import { MultiLineChartComponent } from '~/components/charts/multiLineChart/multiLineChart';
import { MultiStackBarChartComponent } from '~/components/charts/multiStackBarChart/multiStackBarChart';
import { BubblesChartComponent } from '~/components/charts/bubblesChart/bubblesChart';
import { MarketPriceChartComponent } from '~/components/charts/marketPriceChart/marketPriceChart';
import { HorizontalLegendComponent } from '~/components/charts/horizontalLegend/horizontalLegend';
import { VerticalLegendComponent } from '~/components/charts/verticalLegend/verticalLegend';
import { TradeLineAreaChartComponent } from '~/components/charts/tradeLineAreaChart/tradeLineAreaChart';

//Dialogs
import { Dialog } from '~/components/dialog/dialog';
import { DialogConfirm } from '~/components/dialogConfirm/dialogConfirm';
import { DialogInput } from '~/components/dialogInput/dialogInput';
import { DialogSelect } from '~/components/dialogSelect/dialogSelect';
import { DialogMultiSelect } from '~/components/dialogMultiSelect/dialogMultiSelect';
import { DialogDontShow } from '~/components/dialogDontShow/dialogDontShow';

//WEP Modules
import { AppMaterialModule } from '~/modules/materialModule';
import { AppPipesModule } from '~/modules/pipesModule';
import { AppCDKModule }  from '~/modules/cdkModule';
import { AppServiceModule } from '~/modules/servicesModule';
import { AppDirectivesModule } from '~/modules/directivesModule';

//External Modules
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MomentModule } from 'ngx-moment';
import { NouisliderModule } from 'ng2-nouislider';
import { TextMaskModule } from 'angular2-text-mask';
import { AgGridModule } from 'ag-grid-angular';
import { SquareSelectComponent } from '~/components/square/squareInput/squareSelect/squareSelect';
import { AgGridCellRendererImgComponent } from '~/components/agGrid/cellRenderers/img/img';
import { AgGridCellRendererStyledImageComponent } from '~/components/agGrid/cellRenderers/img-style/img';
import { AgGridCellRendererTextComponent } from '~/components/agGrid/cellRenderers/text/text';

@NgModule({
    imports: [
        CommonModule,
        AppMaterialModule,
        AppPipesModule,
        AppCDKModule,
        AppServiceModule,
        AppDirectivesModule,

        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,

        AngularSvgIconModule.forRoot(),
        MomentModule,
        NouisliderModule,
        TextMaskModule,
        AgGridModule.withComponents([
            AgGridCellRendererActionComponent,
            AgGridCellRendererAvatarUserComponent,
            AgGridCellRendererBarComponent,
            AgGridCellRendererCheckboxComponent,
            AgGridHeaderCheckboxComponent,
            AgGridCellRendererIconComponent,
            AgGridCellRendererImgComponent,
            AgGridCellRendererStyledImageComponent,
            AgGridCellRendererTextComponent,
            AgGridCellRendererLinkComponent,
            AgGridCellRendererRadioComponent,
            AgGridCellEditorDatePickerComponent,
            AgGridCellEditorInputComponent,
            AgGridCellEditorInputCurrencyComponent,
            AgGridCellEditorInputCurrencyCentsComponent,
            AgGridCellEditorInputNumberComponent,
            AgGridCellEditorInputReadonlyComponent,
            AgGridNoRowsOverlayComponent,
            AgGridCellRendererMarketAuctionButtonComponent,
            AgGridCellRendererMarketAuctionDetailsComponent,
            AgGridCellRendererMarketAuctionStickyComponent,
            AgGridCellRendererMarketAuctionStatusComponent,
            AgGridCellRendererMarketAuctionPlainComponent,
            AgGridCellRendererMarketAuctionLimitSelectComponent,
            AgGridCellRendererMarketAdminAuctionStatusComponent,
            AgGridCellRendererMarketAdminTenderStatusComponent,
            AgGridCellRendererMarketOrderBookStatusComponent,
        ])
    ],
    declarations: [
        TabComponent,
        TabsComponent,
        UploadCertificateComponent,
        UploadImageComponent,
        UploadVideoComponent,
        UploadGalleryComponent,
        SquareBlockComponent,
        SquareButtonComponent,
        SquareInputTextComponent,
        SquareInputButtonComponent,
        SquareInputButtonVariantComponent,
        SquareInputToggleComponent,
        SquareInputButtonComponent,
        SquareSelectComponent,
        SquareGridComponent,
        SquareGridSmallComponent,
        DatePickerComponent,
        SignaturePadComponent,
        ChipsComponent,
        ColorSelectComponent,
        ColorOptionComponent,
        ButtonComponent,
        ButtonGroupComponent,
        ButtonRadioComponent,
        ButtonRadioOptionComponent,
        FilterButtonComponent,
        FilterButtonGroupComponent,
        SnackBarComponent,
        CarouselComponent,
        CarouselItemComponent,
        PriceBoxComponent,
        InsightBigTextComponent,
        InsightTitleComponent,
        ContentBoxComponent,
        PasswordComponent,
        AwexListComponent,
        SearchComponent,
        SelectComponent,
        OptionComponent,
        SelectMenuComponent,
        MenuOptionComponent,
        SelectPriceComponent,
        SliderComponent,
        BarChartComponent,
        PieChartComponent,
        LineChartComponent,
        MultiSymbolChartComponent,
        LineAreaChartComponent,
        MultiBarChartComponent,
        MultiLineChartComponent,
        MultiStackBarChartComponent,
        BubblesChartComponent,
        HorizontalLegendComponent,
        VerticalLegendComponent,
        TradeLineAreaChartComponent,
        MarketPriceChartComponent,
        FaqItemComponent,
        DialogTooltipComponent,
        traceabilitySummaryComponent,

        StepperComponent,
        StepComponent,
        PaginationComponent,
        AgGridComponent,
        AgGridDirective,
        AgGridCellRendererActionComponent,
        AgGridCellRendererAvatarUserComponent,
        AgGridCellRendererBarComponent,
        AgGridCellRendererCheckboxComponent,
        AgGridHeaderCheckboxComponent,
        AgGridCellRendererIconComponent,
        AgGridCellRendererImgComponent,
        AgGridCellRendererStyledImageComponent,
        AgGridCellRendererTextComponent,
        AgGridCellRendererLinkComponent,
        AgGridCellRendererRadioComponent,
        AgGridCellEditorDatePickerComponent,
        AgGridCellEditorInputComponent,
        AgGridCellEditorInputCurrencyComponent,
        AgGridCellEditorInputCurrencyCentsComponent,
        AgGridCellEditorInputNumberComponent,
        AgGridCellEditorInputReadonlyComponent,
        AgGridNoRowsOverlayComponent,
        AgGridCellRendererMarketAuctionButtonComponent,
        AgGridCellRendererMarketAuctionDetailsComponent,
        AgGridCellRendererMarketAuctionStickyComponent,
        AgGridCellRendererMarketAuctionStatusComponent,
        AgGridCellRendererMarketAuctionPlainComponent,
        AgGridCellRendererMarketAuctionLimitSelectComponent,
        AgGridCellRendererMarketAdminAuctionStatusComponent,
        AgGridCellRendererMarketAdminTenderStatusComponent,
        AgGridCellRendererMarketOrderBookStatusComponent,

        GalleryComponent,
        GalleryItemComponent,
        GalleryOverlayComponent,
        ImageComponent,
        EmptyComponent,
        LoadingComponent,
        ListComponent,
        ListItemComponent,
        ListRowComponent,
        ListCellComponent,
        PostEditorComponent,
        NotificationComponent,
        NotificationDirective,
        NotificationErrorComponent,
        AvatarComponent,
        AvatarUserComponent,
        AutocompleteComponent,
        BoxComponent,
        FeedItemComponent,
        DataSharingInfoBoxComponent,

        Dialog,
        DialogConfirm,
        DialogInput,
        DialogSelect,
        DialogMultiSelect,
        DialogDontShow,
        DeletePostDialog,
        DeletePostCommentDialog,
    ],
    providers: [
        SnackBarService
    ],
    exports: [
        CommonModule,
        AppMaterialModule,
        AppPipesModule,
        AppCDKModule,
        AppDirectivesModule,

        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,

        AngularSvgIconModule,
        MomentModule,
        NouisliderModule,

        TabComponent,
        TabsComponent,
        UploadCertificateComponent,
        UploadImageComponent,
        UploadVideoComponent,
        UploadGalleryComponent,
        SquareBlockComponent,
        SquareButtonComponent,
        SquareInputTextComponent,
        SquareInputButtonComponent,
        SquareSelectComponent,
        SquareInputButtonVariantComponent,
        SquareInputToggleComponent,
        SquareGridComponent,
        SquareGridSmallComponent,
        DatePickerComponent,
        SignaturePadComponent,
        ChipsComponent,
        ColorSelectComponent,
        ColorOptionComponent,
        ButtonComponent,
        ButtonGroupComponent,
        ButtonRadioComponent,
        ButtonRadioOptionComponent,
        FilterButtonComponent,
        FilterButtonGroupComponent,
        SnackBarComponent,
        CarouselComponent,
        CarouselItemComponent,
        PriceBoxComponent,
        InsightBigTextComponent,
        InsightTitleComponent,
        ContentBoxComponent,
        PasswordComponent,
        AwexListComponent,
        SearchComponent,
        SelectComponent,
        OptionComponent,
        SelectMenuComponent,
        MenuOptionComponent,
        SelectPriceComponent,
        SliderComponent,
        BarChartComponent,
        PieChartComponent,
        LineChartComponent,
        MultiSymbolChartComponent,
        LineAreaChartComponent,
        MultiBarChartComponent,
        MultiLineChartComponent,
        MultiStackBarChartComponent,
        BubblesChartComponent,
        HorizontalLegendComponent,
        VerticalLegendComponent,
        TradeLineAreaChartComponent,
        MarketPriceChartComponent,
        FaqItemComponent,
        DialogTooltipComponent,
        traceabilitySummaryComponent,

        StepperComponent,
        StepComponent,
        PaginationComponent,
        AgGridComponent,
        AgGridDirective,
        AgGridCellRendererActionComponent,
        AgGridCellRendererAvatarUserComponent,
        AgGridCellRendererBarComponent,
        AgGridCellRendererCheckboxComponent,
        AgGridHeaderCheckboxComponent,
        AgGridCellRendererIconComponent,
        AgGridCellRendererImgComponent,
        AgGridCellRendererStyledImageComponent,
        AgGridCellRendererTextComponent,
        AgGridCellRendererLinkComponent,
        AgGridCellRendererRadioComponent,
        AgGridNoRowsOverlayComponent,
        AgGridCellRendererMarketAuctionButtonComponent,
        AgGridCellRendererMarketAuctionDetailsComponent,
        AgGridCellRendererMarketAuctionStickyComponent,
        AgGridCellRendererMarketAuctionStatusComponent,
        AgGridCellRendererMarketAuctionPlainComponent,
        AgGridCellRendererMarketAuctionLimitSelectComponent,
        AgGridCellRendererMarketAdminAuctionStatusComponent,
        AgGridCellRendererMarketAdminTenderStatusComponent,
        AgGridCellRendererMarketOrderBookStatusComponent,
        AgGridCellEditorDatePickerComponent,
        AgGridCellEditorInputComponent,
        AgGridModule,

        GalleryComponent,
        GalleryItemComponent,
        GalleryOverlayComponent,
        ImageComponent,
        EmptyComponent,
        LoadingComponent,
        ListComponent,
        ListItemComponent,
        ListRowComponent,
        ListCellComponent,
        PostEditorComponent,
        NotificationComponent,
        NotificationDirective,
        NotificationErrorComponent,
        AvatarComponent,
        AvatarUserComponent,
        AutocompleteComponent,
        BoxComponent,
        FeedItemComponent,
        DataSharingInfoBoxComponent,

        Dialog,
        DialogConfirm,
        DialogInput,
        DialogSelect,
        DialogMultiSelect,
        DialogDontShow,
        DeletePostDialog,
        DeletePostCommentDialog,

        TextMaskModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})

export class SharedModule {
}
