<wep-page-tpl-admin-default>
    <wep-page-tpl-admin-default-heading-left>
        <ng-container *ngIf="eSpeciViewState === ESpeciViewState.SetupBrandView">
            <span>eSpeci</span>
        </ng-container>
        <ng-container *ngIf="eSpeciViewState === ESpeciViewState.SpecieView || eSpeciViewState === ESpeciViewState.SetupClipView">
            <ng-container *ngIf="currentBrand && currentBrand.brandName">
                <span class="show-for-medium">{{currentBrand.brandName | wepLimitTo:25 }}</span>
                <span class="hide-for-medium">{{currentBrand.brandName | wepLimitTo:16 }}</span>
                <a (click)="displaySetupBrandView()" href="javascript:void(0)" class="page__header__link">Change</a>
                <span class="hide-for-medium">
                    <br/>
                </span>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="eSpeciViewState === ESpeciViewState.SpecieView">
            <ng-container *ngIf="currentClip && currentClip.clipReference">
                <span class="show-for-medium">{{currentClip.clipReference | wepLimitTo:25 }}</span>
                <span class="hide-for-medium">{{currentClip.clipReference | wepLimitTo:16 }}</span>
                <a (click)="displaySetupClipView()" href="javascript:void(0)" class="page__header__link">Change</a>
            </ng-container>
        </ng-container>
    </wep-page-tpl-admin-default-heading-left>
    <wep-page-tpl-admin-default-content>
        <div>
            <div class="row">
                <div class="small-12 medium-6 columns">
                    <wool-clip-instruction [generic]="true"></wool-clip-instruction>
                </div>
                <div class="small-12 medium-6 columns right-column" *ngIf="!currentBrand || !currentBrand.woolClipLinked">
                    <wep-button (click)="onConnectToWoolClipClicked()"> Connect to WoolClip </wep-button>
                    <h3>
                        <a href="https://www.woolclip.com.au" target="_blank">Go to WoolClip</a>
                    </h3>
                </div>
                <div class="small-12 medium-6 columns right-column" *ngIf="currentBrand && currentBrand.woolClipLinked">
                    <wep-button> Accounts are Linked </wep-button>
                    <h3>
                        <a href="https://www.woolclip.com.au" target="_blank">Go to WoolClip to complete your Speci</a>
                    </h3>
                </div>
            </div>
        </div>
    </wep-page-tpl-admin-default-content>
</wep-page-tpl-admin-default>
