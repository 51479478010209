import { BaseModel } from '~/models/baseModel';

export class DataVisibilityModel extends BaseModel {
    public brandPubliclyVisible: boolean;
    public shareBuyerDataDownstreamGlobal: boolean;
    public shareBrandDataWithBuyerDefault: boolean;

    public hasValues() : boolean {
        let hasValues = (typeof this.brandPubliclyVisible !== 'undefined') || 
        (typeof this.shareBuyerDataDownstreamGlobal !== 'undefined') || 
        (typeof this.shareBrandDataWithBuyerDefault !== 'undefined');

        return hasValues;
    }
}
