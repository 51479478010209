import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from '~/services/api/auth/authService';

@Injectable()
export class GuestGuard implements CanActivate {

    private authService: AuthService;
    private router: Router;

    constructor(authService: AuthService, 
        router: Router) {
            this.authService = authService;
            this.router = router;
    }

    public canActivate() : Promise<boolean> {
        return this.authService.hasValidAuthToken().then((isValid) => {
            if (isValid){
                this.router.navigate(['/dashboard']);
            }
            else {
                return true;
            }
        });
    }
}
