import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { HttpClientWrapper } from '~/services/api/httpClientWrapper';
import { BaseWebService } from '~/services/api/web/base/baseWebService';
import { ResponseModel } from '~/models/responseModel';
import { Observable } from 'rxjs';
import { BreakDownModel, MobBreakDownModel } from '~/models/breakDownModel';
import { InventoryBaleModel } from '~/models/inventoryBaleModel';
import { EntityModel } from '~/models/entityModel';
import { BrandModel } from '~/models/brandModel';
import { InsightModel } from '~/models/insightModel';
import { map as _map } from 'lodash';
import { InventoryLotModel } from '~/models/inventoryLotModel';
import { InventoryMobModel } from '~/models/inventoryMobModel';
import { InventoryClipModel } from '~/models/inventoryClipModel';
import { SellerInventoryAbstractService } from './sellerInventoryAbstractService';

@Injectable()
export class SellerInventoryService extends BaseWebService implements SellerInventoryAbstractService {

    public static readonly PATH: string = '/sellerinventory';

    constructor(http: HttpClientWrapper) {
        super(http);
    }

    public getMobs(entity?: EntityModel, brand?: BrandModel): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/mobs';

        let params = new HttpParams();

        if (entity.entityId) {
            params = params.append('entityId', entity.entityId.toString());
        }

        if (brand.brandId) {
            params = params.append('brandId', brand.brandId.toString());
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (mob) => new InventoryMobModel(mob));
        });
    }

    public getClips(entityId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips';

        let params = new HttpParams();
        if (entityId) {
            params = params.append('entityId', entityId.toString());
        }
        if (sellerId) {
            params = params.append('sellerId', sellerId.toString());
        }

        let options:{} = {
            params: params
        };

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (clip) => new InventoryClipModel(clip));
        });
    }

    public getClipById(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString();
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return new InventoryClipModel(response['data']);
        });
    }

    public getClipRelatedMobItems(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString() + '/mobs';
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (mob) => new InventoryMobModel(mob));
        });
    }

    public getClipRelatedBaleItems(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString() + '/bales';
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (bale) => new InventoryBaleModel(bale));
        });
    }

    public getClipRelatedLineAndLotItems(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString() + '/linesandlots';
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (line) => new InventoryLotModel(line));
        });
    }

    public getClipInsight(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString() + '/insight';
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response:HttpResponse<any>) => {
            return new InsightModel(response['data']);
        });
    }

    public getWooltypeByClip(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString() + '/balesbywooltype';
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new BreakDownModel(inventoryMob));
        });
    }

    public getBaleBreakDownByClips(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString() + '/balesbyclip';
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new BreakDownModel(inventoryMob));
        });
    }

    public getMobBreakDownByClips(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString() + '/mob-breakdown';
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new MobBreakDownModel(inventoryMob));
        });
    }

    public getBalesByMicron(clipId: number, sellerId: number): Observable<ResponseModel> {
        let url:string = SellerInventoryService.URL + SellerInventoryService.PATH + '/clips/' + clipId.toString() + '/balesbymicron';
        let options = this.getOptionsWithSellerId(sellerId);

        return this.http.get(url, options, (response: HttpResponse<any>) => {
            return _map(response['data'], (inventoryMob:{}) => new BreakDownModel(inventoryMob));
        });
    }

    private getOptionsWithSellerId(sellerId: number){
        let params = new HttpParams();

        if (sellerId) {
            params = params.append('sellerId', sellerId.toString());
        }

        return {
            params: params
        };
    }
}
