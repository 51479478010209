import { BaseModel } from '../baseModel';
import { map as _map } from 'lodash';
import { ServerFilterModel } from './serverFilterModel';

export class ServerPaginationModel<T> extends BaseModel {
    public total:number;
    public result:T[];
    public filter: ServerFilterModel;
    public offset:number;
    public pageSize: number;
    public entityId: number;
    public hasObfuscatedData: boolean;
    public items:T[];

    constructor(type:{ new (values:Object): T }, values: Object = {}) {
        super(values);

        if(values['result']) {
            this.result = _map(values['result'], (item) => {
                return new type(item);
            });
        }
    }
}