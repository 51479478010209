import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { isFunction as _isFunction } from 'lodash';

@Component({
    selector: 'wep-ag-grid-cell-renderer-styled-image',
    templateUrl: './img.html',
    host: { 'class' : 'wep-ag-grid-cell-renderer-styled-image' },
    styleUrls: ['./img.scss']
})

export class AgGridCellRendererStyledImageComponent  {
    public params: any;
    public images: [];

    public agInit(params: any) : void {
        this.params = params;

        if(_isFunction(this.params.images)) {
            this.images = this.params.images(this.params);
        }
    }

    public refresh() : boolean {
        return false;
    }
}
