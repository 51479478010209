import { BaseModel } from '~/models/baseModel';
import { forOwn as _forOwn } from 'lodash';

interface GrowerStatusInterface {
    businessCreated?:boolean;
    brandCreated?:boolean;
    hasPartners?:boolean;
    speciCreated?:boolean;
}

interface ClasserStatusInterface {
    businessCreated?:boolean;
    speciCreated?:boolean;
}

interface SellerStatusInterface {
    businessCreated?:boolean;
    hasWoolshedLocations?:boolean;
}

interface BuyerStatusInterface {
    businessCreated?:boolean;
}

export class OnBoardingModel extends BaseModel {
    public growerStatus:GrowerStatusInterface;
    public classerStatus:ClasserStatusInterface;
    public sellerStatus:SellerStatusInterface;
    public buyerStatus:BuyerStatusInterface;

    public businessSetUpUrl:string;
    public addBrandToBusinessUrl:string;
    public addWoolStoreUrl:string;
    public connectToPartnerUrl:string;
    public startESpecieUrl:string;

    public isGrowerStatusIncomplete() : boolean {
        return this.isStatusIncomplete(this.growerStatus);
    }

    public isClasserStatusIncomplete() : boolean {
        //Override classerStatus
        this.classerStatus = {
            businessCreated : this.classerStatus.businessCreated,
            speciCreated : this.classerStatus.businessCreated
        };
        return this.isStatusIncomplete(this.classerStatus);
    }

    public isSellerStatusIncomplete() : boolean {
        //Override sellerStatus
        this.sellerStatus = {
            businessCreated : this.sellerStatus.businessCreated,
            hasWoolshedLocations : this.sellerStatus.hasWoolshedLocations
        };
        return this.isStatusIncomplete(this.sellerStatus);
    }

    public isBuyerStatusIncomplete() : boolean {
        //Override buyerStatus
        this.buyerStatus = {
            businessCreated : this.buyerStatus.businessCreated,
        };
        return this.isStatusIncomplete(this.buyerStatus);
    }

    private isStatusIncomplete(statusInterface) : boolean {
        let found = false;
        _forOwn(statusInterface, (status:boolean) => {
            if(status === false) {
                found = true;
                return;
            }
        });
        return found;
    }
}
